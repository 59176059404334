//------------------------------------
// text-color utility class
//------------------------------------
 
$utility-text-color-prefix: null !default;
$utility-text-colors: null !default;
$utility-text-color-link-style: null !default;
 
@if $utility-text-colors != null {
 
   @each $class-name, $color in $utility-text-colors {
 
     $text-color: nth($color, 1);
     $text-color-hover: nth($color, 2);
 
     @if $utility-text-color-prefix != null {
         
       .#{$utility-text-color-prefix}#{$class-name} {
           color: $text-color;
 
           a {
             &:hover {
               color: $text-color-hover;
             }
           }
 
       }
 
     }
 
     @else {
       .#{$class-name} {
           color: $text-color;
 
           a {
             &:hover {
               color: $text-color-hover;
             }
           }
       }
     }
 
   }
 
   @if $utility-text-color-prefix != null {
       
       [class*="#{$utility-text-color-prefix}"] {
           > * {
             color: inherit;
 
             a {
               color: inherit;
 
               @if $utility-text-color-link-style == dotted {
                 border-bottom: 1px dotted;
                 border-bottom-color: inherit;
               }
 
             }
           }
 
           > hr {
             border-color: inherit;
           }
       }
   }
 
}