// SVG Icons 
//------------------------------------ 

$svg-icon-base-size: null !default; 


// placeholder for all shared base icon styles
@include placeholder('svg-icon-base') { 
    display: inline-block; 
    background-repeat: no-repeat; 
    background-position: 50% 50%; 
    width: $svg-icon-base-size; 
    height: $svg-icon-base-size; 
    background-size: 100%;
} 

// used to change colors or swap icons on hover and active states
// doesn't duplicate svg styling || swaps only svg code
@mixin svg-icon-swap (
    $icon-name: null,
    $color: $white, 
    $stroke-color: null, 
    $stroke-width: null
  ) {
      background-image: svg-icon($icon-name, $color, $stroke-color, $stroke-width);
}


// BASIC ICON
//-------------------------------------------------

// main icon mixin
@mixin svg-icon ( 
  $icon-name: null,
  $size: null,
  $color: $black, 
  $stroke-color: null, 
  $stroke-width: null
  ) {
      width: $size;
      height: $size;
      background-image: svg-icon($icon-name, $color, $stroke-color, $stroke-width);
      @include _(svg-icon-base);
}


// CIRCLE ICON
//-------------------------------------------------

// main circle icon mixin
@mixin svg-circle-icon (
      $icon-name: null,
      $size: null,
      $icon-size: null,
      $color: null, 
      $stroke-color: null, 
      $stroke-width: null,
      $background-color: null
) {

    @include _(make-circle);
    @include _(svg-icon-base);

    background-image: svg-icon($icon-name, $color, $stroke-color, $stroke-width);
    background-color: $background-color;
    background-size: $icon-size; 
    width: $size;
    height: $size;
    line-height: $size;
    
}

// BACKGROUND IMAGE ICON
//-------------------------------------------------

// placeholder for shared background image icons
@include placeholder('svg-background-icon') {
    background-repeat: no-repeat;
    background-size: contain;
}

// mixin for position background image icons
@mixin svg-background-icon-position ($icon-position, $icon-padding ) {
    
    @if $icon-position == 'left' {
        background-position: left;
        padding-left: $icon-padding;
    } 

    @if $icon-position == 'right' {
        background-position: right;
        padding-right: $icon-padding;
    }
}

// main background icon mixin
@mixin svg-background-icon ( 
    $icon-name: null, 
    $color: null, 
    $stroke-color: null, 
    $stroke-width: null,
    $icon-position: null, 
    $icon-padding: null
  ) {
      background-image: svg-icon($icon-name, $color, $stroke-color, $stroke-width);
      @include _(svg-background-icon);
      @include svg-background-icon-position($icon-position, $icon-padding);
}


