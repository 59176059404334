.hero_new {
  @include _(p-s);
  @include breakpoint(medium) {
    @include _(ph-l);
    @include _(pv-l);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .inside {
      display: flex;
      justify-content: center;
      width: 100%;

      h1 {
        @include _(fw-xbold);
      }
    }

    @include breakpoint(medium) {
      flex-direction: row;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint(medium) {
      width: 50%;
    }
    h1 {
      @include _(fw-xbold);
      @include _(fs-l);
      color: $grey-90;
    }
    p {
      @include _(fs-ms);
      color: $grey-90;

      a {
        color: $white;
        text-decoration: underline;
      }
    }
    .button_wrapper {
      @include _(mt-s);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include breakpoint(medium) {
        @include _(mt-xs);
        flex-direction: row;
      }

      div {
        width: 100%;
      }
    }

    .cta-primary {
      @include _(fs-ms);
      @include _(mt-none);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100% !important;
      height: 100%;
      @include breakpoint(medium) {
        width: 90% !important;
      }
    }

    button {
      @include _(fs-ms);
      @include _(p-none);
      margin-top: 2rem;
      background: transparent;
      border: 0;
      width: 100%;
      @include breakpoint(medium) {
        margin-top: 0;
        width: 90%;
      }

      .cta-secondary {
        @include _(mt-none);
        @include _(fs-ms);
      }
    }
  }

  &__logo {
    display: none;
    text-align: center;
    @include breakpoint(medium) {
      display: block;
      img {
        max-width: 50%;
      }
    }
  }
}

.hero {
  @include _(ph-m);
  @include _(pb-s);
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  color: $white;

  // .cta-secondary {
  //   @include breakpoint(medium) {
  //     width: 45% !important;
  //   }
  // }

  .cta-primary {
    @include breakpoint(medium) {
      margin-right: 15px;
      width: 45% !important;
    }
  }

  .caption {
    @include _(ph-xs);
    @include _(fs-xs);
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  &-home {
    background-image: linear-gradient(130deg, $charcoal 0%, $dark-grey 100%);
    background-size: cover;
    overflow: hidden;
    position: relative;
    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   background-color: rgba(0, 0, 0, 0.4);
    // }
    &__cta {
      @include breakpoint(medium) {
        width: 20%;
      }
      margin: 2rem auto;
      border: 0;
      background: transparent;
      span {
        margin: 0;
        border-bottom-width: 0;
      }
    }

    .ov-description {
      @include _(ph-xs);
      @include _(pb-l);
      // padding-top: 1rem;
      // padding-bottom: 3rem;
      // background-image: linear-gradient(130deg, $charcoal 0%, $dark-grey 100%);
      @include breakpoint(medium) {
        // height: 450px;
        &:before {
          content: "";
          // background: url("../../images/triangle-vertical.png");
          background-repeat: no-repeat;
          width: 100%;
          height: 600px;
          position: absolute;
          // z-index: 1;
          left: 15px;
          // top: -6.125rem;
        }
      }

      .constrain {
        max-width: 1800px;
        margin: 0 auto;
      }

      h1 {
        @include _(fs-ml);
        @include _(mt-none);
        @include _(mb-xs);
        @include _(fw-xbold);
        color: $white;
        @include breakpoint(medium) {
          font-size: 3rem;
          @include _(lh-xxl);
        }
      }

      &__img {
        z-index: 9999;
        margin: 0 auto;
        @media (min-width: 1600px) {
          margin-top: 2rem !important;
          img {
            width: 100%;
          }
        }
        @include breakpoint(small) {
          margin-top: 0 !important;
          width: auto;
          // @include _(pv-xs);
        }
      }

      &__text {
        @include _(pv-xs);
        z-index: 9999;
        margin-top: 0rem !important;
        @include breakpoint(small) {
          // @include _(pv-m);
          // height: 350px;
        }
        @media (min-width: 1200px) {
          margin-top: 2rem !important;
        }
        p {
          @include _(fs-ms);
          color: $white;
        }
      }
      @include breakpoint(medium) {
        &__slant {
          height: 68px;
          transform: skewY(-2deg);
          background-color: $white;
          position: relative;
          bottom: 25px;
        }
      }
    }
  }

  @include breakpoint(small) {
    height: 55vh;
    // min-height: 15rem;
  }

  &__content {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    text-align: center;

    // @include breakpoint(large) {
    //   width: $content-max-width;
    // }

    @include breakpoint(site-max) {
      // flex-direction: row;

      // First element 66% width
      // & > :nth-child(1) {
      //   flex: 1 0 70%;
      // }

      // Space between horizontal sections
      // & > * + * {
      //   margin-left: spacing(ms);
      // }
    }

    h1 {
      @include _(fs-ml);
      @include _(mt-none);
      @include _(mb-xs);
      @include _(fw-xbold);
      color: $white;
      @include breakpoint(medium) {
        font-size: 4.20873rem;
        @include _(lh-xxl);
      }
    }

    button {
      width: 100%;
      margin: 0 auto;
      @include breakpoint(small) {
        width: 50%;
      }
    }

    p {
      @include _(fs-ms);
      @include _(mt-xs);
      @include _(fw-light);
    }
  }
}
