
//---------------------------------------------------------------------------------------
// BREAKPOINTS - functions create breakpoints and allow placeholders to work within them
//---------------------------------------------------------------------------------------

$breakpoints: () !default;
$default-breakpoint: root;
$current-breakpoint: $default-breakpoint;
$placeholders: ();

// Function for creating Maps
//-----------------------------------------

/// Gets a value from a map.
/// @param {map} $map Map.
/// @param {string} $keys Key(s).
/// @return {string} Value.
@function val($map, $keys...) {

  @if nth($keys, 1) == null {
    $keys: remove-nth($keys, 1);
  }

  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;

}


// Breakpoint Mixin
@mixin breakpoint($breakpoint) {
      // Get the width from the keyword `$breakpoint`
      // Or `null` if the keyword doesn't exist in `$breakpoints` map
      $value: map-get($breakpoints, $breakpoint);

  // If `$breakpoint` exists as a key in `$breakpoints`
  @if $value != null {
        // Update `$current-breakpoint`
        $current-breakpoint: $breakpoint !global;

    // Open a media query block
    @media (min-width: $value) {
      // Let the user dump content
      @content;
    }

    // Then reset `$current-breakpoint` to `$default-breakpoint` (root)
    $current-breakpoint: $default-breakpoint !global;
  }

  // If `$breakpoint` doesn't exist in `$breakpoints`, 
  // Warn the user and do nothing
  @else {
    @warn "Invalid breakpoint `#{$breakpoint}`.";
  }
}

// Mixin to allow you to create a placeholder
@mixin placeholder($name) {

  // If placeholder doesn't exist yet in `$placeholders` list
  @if not index($placeholders, $name) {
    // Store its name
    $placeholders: append($placeholders, $name) !global;

    // And dumping a placeholder out of any media query as well
    // so basically at root level
    %#{$name}-#{$default-breakpoint} {
      @content;
    }
    
    // At root level
    @at-root {
      // Looping through `$breakpoints`
      @each $breakpoint, $value in $breakpoints {
        // Opening a media query block
        @media (min-width: $value) {
          // Generating a placeholder
          // Called $name-$breakpoint 
          %#{$name}-#{$breakpoint} {
            @content;
          }
        }
      }
    }
  }

  // If placeholder already exists, just warn the user
  @else {
    @warn "Placeholder `#{$name}` already exists.";
  }
}

// Mixin that passes placeholders into the breakpoints
@mixin _($name) {
      @extend %#{$name}-#{$current-breakpoint} !optional;
}

//------------- End Breakpoint Functions -----------------------------//


