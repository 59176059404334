// ----------------------------------------------------------------
// **************** CONFIG GLOBAL STYLES **************************
//-----------------------------------------------------------------

// ----------------------------------------
// LOAD GLOBAL STYLES
// ----------------------------------------

html,
body {
  min-height: 100%;
  width: 100%;
  position: relative;
}

body {
  width: 100%;
  @include _(overflow-scroll-y);
  transition: transform $global-transition-speed ease-in-out;

  &.no-scroll {
    overflow: hidden;
  }

  &.menu-active {
    overflow: hidden;
    position: fixed;
    backface-visibility: hidden;
  }
}

img {
  width: 100%;
  max-width: 100%;
}

// set layout
main {
  @include _(pb-m);
  z-index: 0;
  position: relative;

  @include breakpoint(medium) {
    @include _(pb-l);
  }

  > div > * {
    @include _(content-wrap);
  }
}

ul,
ol {
  padding-left: $vr-base + rem;
}

.no-wrap {
  @include _(no-wrap);
}

.visually-hidden {
  @include _(visually-hidden);
}
