//------------------------------------
// TYPOGRAPHY
//------------------------------------

// Ratios
//----------------------------------------
$minor-second: 1.067 !default;
$major-second: 1.125 !default;
$minor-third: 1.2 !default;
$major-third: 1.25 !default;
$perfect-fourth: 1.333 !default;
$aug-fourth: 1.414 !default;
$perfect-fifth: 1.5 !default;
$minor-sixth: 1.6 !default;
$golden: 1.618 !default;
$major-sixth: 1.667 !default;
$minor-seventh: 1.778 !default;
$major-seventh: 1.875 !default;
$octave: 2 !default;
$major-tenth: 2.5 !default;
$major-eleventh: 2.667 !default;
$major-twelfth: 3 !default;
$double-octave: 4 !default;

// Settings
$vertical-rhythm: false !default;
$type-styling: false !default;
$modular-scale: false !default;
$ms-base: 1 !default;

$line-height-ratio: $vr-base !default; // keep set to $vr-base
$ms-ratio: 1 !default;

$body-font: null !default;
$body-font-weight: null !default;
$body-font-color: null !default;
$base-font-size: null !default;
$base-line-height: null !default;
// $body-font-size           : null !default;

$heading-font: null !default;
$heading-font-weight: null !default;
$heading-font-color: inherit !default;

$headings: null !default;
$non-ms-headings: null !default;

$font-size-modifier-prefix: null !default;
$font-size-modifier-line-height-base: null !default;

$font-size-modifiers: null !default;

$font-weight-modifier-prefix: null !default;
$font-weight-modifiers: null !default;

$line-height-modifiers: null !default;

// $min-font            	: null !default;
// $max-font            	: null !default;
// $min-width           	: null !default;
// $max-width           	: null !default;

$responsive-scaling: false !default;
$responsive-scaling-breakpoints: false !default;

// Import Functions for Modular Scale
//----------------------------------------

@import "modular-scale/tests";
@import "modular-scale/pow";
@import "modular-scale/calc";
@import "modular-scale/generate-list";
@import "modular-scale/sort-list";
@import "modular-scale/round-px";
@import "modular-scale/function";
@import "modular-scale/function-list";
@import "modular-scale/respond";

// load fonts functions
//-------------------------------------------
$load-heading-font: null !default;
$heading-font-family: null !default;
$heading-font-path: null !default;

$load-body-font: null !default;
$body-font-family: null !default;
$body-font-path: null !default;

$font-style: null !default;
$font-weight: null !default;

@if $load-heading-font != null {
  @each $font-file, $font in $load-heading-font {
    $font-style: nth($font, 1);
    $font-weight: nth($font, 2);

    @font-face {
      font-family: $heading-font-family;

      src: url("#{$heading-font-path}/#{$font-file}.eot");
      src: url("#{$heading-font-path}/#{$font-file}.eot?#iefix")
          format("embedded-opentype"),
        url("#{$heading-font-path}/#{$font-file}.woff") format("woff"),
        url("#{$heading-font-path}/#{$font-file}.ttf") format("truetype"),
        url("#{$heading-font-path}/#{$font-file}.svg#open_sansregular")
          format("svg");

      font-style: $font-style;
      font-weight: $font-weight;
    }
  }
}

@if $load-body-font != null {
  @each $font-file, $font in $load-body-font {
    $font-style: nth($font, 1);
    $font-weight: nth($font, 2);

    @font-face {
      font-family: $body-font-family;

      src: url("#{$body-font-path}/#{$font-file}.eot");
      src: url("#{$body-font-path}/#{$font-file}.eot?#iefix")
          format("embedded-opentype"),
        url("#{$body-font-path}/#{$font-file}.woff") format("woff"),
        url("#{$body-font-path}/#{$font-file}.ttf") format("truetype");
      font-style: $font-style;
      font-weight: $font-weight;
    }
  }
}

// Typography Mixins

@mixin t-html() {
  font-family: $body-font;
  font-size: $base-unit;
  font-weight: $body-font-weight;
  color: $body-font-color;

  @if $vertical-rhythm != false {
    line-height: #{$line-height-ratio}em;
  } @else {
    line-height: normal;
  }
}

@mixin t-p() {
  @if $vertical-rhythm != false {
    line-height: #{$line-height-ratio}rem;
  } @else {
    line-height: inherit;
  }
}

@mixin t-reset() {
  margin: 0;
  padding: 0;
}

@mixin t-block() {
  @if $vertical-rhythm != false {
    margin-top: #{$line-height-ratio}rem;
  } @else {
    margin-top: normal;
  }

  @if $modular-scale == false {
    font-size: $base-font-size;
    line-height: $base-line-height;
  }
}

@mixin t-heading() {
  font-family: $heading-font;
  font-weight: $heading-font-weight;
  color: $heading-font-color;

  @if $modular-scale == false {
    line-height: normal;
  }
}

/// The Typographic Reset. This is where Typographic goes through every markup element and styles it to adhere to a vertical rhythm. After you set your variables in a settings file fire this mixin.
///
/// @example
///   @include typographic;

@mixin typographic() {
  html {
    @if $type-styling != false {
      @include t-html;
    }

    @if $responsive-scaling != false {
      @each $breakpoint, $size in $responsive-scaling-breakpoints {
        $font-size: nth($size, 1);

        @media (min-width: $breakpoint) {
          font-size: $font-size;
        }
      }
    }
  }

  @if $vertical-rhythm != false {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
      @include t-reset;
    }

    p + p,
    ul + p,
    ol + p,
    blockquote,
    cite,
    pre,
    address,
    dl,
    ol,
    ul,
    table {
      @include t-block;
    }
  }

  @if $type-styling != false {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include t-heading;
    }
  }

  // implementing modular scale functions
  @if $modular-scale != false {
    @if $headings != null {
      @each $h-tag, $value in $headings {
        $ms-step: nth($value, 1);
        $line-height: nth($value, 2);
        $h-margin-top: nth($value, 3);
        $h-margin-bottom: nth($value, 4);

        #{$h-tag} {
          font-size: ms($ms-step) + rem;
          line-height: $line-height;
          margin-top: $h-margin-top;
          margin-bottom: $h-margin-bottom;
        }
      }
    }

    @if $font-size-modifiers != null {
      @each $modifier-name, $value in $font-size-modifiers {
        $font-size: nth($value, 1);
        $line-height: nth($value, 2);

        @if $font-size-modifier-prefix != null {
          .#{$font-size-modifier-prefix}#{$modifier-name} {
            @include _(#{$font-size-modifier-prefix}#{$modifier-name});
          }

          @include placeholder(
            "#{$font-size-modifier-prefix}#{$modifier-name}"
          ) {
            font-size: ms($font-size) + rem;
            line-height: $line-height;
          }
        } @else {
          .#{$modifier-name} {
            @include _(#{$modifier-name});
          }

          @include placeholder("#{$modifier-name}") {
            font-size: ms($font-size) + rem;
            line-height: $line-height;
          }
        }
      }
    }

    //set p tags to $ms-base font size
    p,
    blockquote,
    cite,
    pre,
    address,
    dl,
    ol,
    ul,
    table {
      font-size: $ms-base + rem;
    }
  }

  // typographic functions when NOT using Modular Scale
  @if $modular-scale == false {
    @if $non-ms-headings != null {
      @each $h-tag, $value in $non-ms-headings {
        $size: nth($value, 1);
        $line-height: nth($value, 2);
        $h-margin-top: nth($value, 3);
        $h-margin-bottom: nth($value, 4);

        #{$h-tag} {
          font-size: $size;
          line-height: $line-height;
          margin-top: $h-margin-top;
          margin-bottom: $h-margin-bottom;
        }
      }
    }

    @if $font-size-modifiers != null {
      @each $modifier-name, $value in $font-size-modifiers {
        $font-size: nth($value, 1);
        $line-height: nth($value, 2);

        @if $font-size-modifier-prefix != null {
          .#{$font-size-modifier-prefix}#{$modifier-name} {
            @include _(#{$font-size-modifier-prefix}#{$modifier-name});
          }

          @include placeholder(
            "#{$font-size-modifier-prefix}#{$modifier-name}"
          ) {
            font-size: $font-size;
            line-height: $line-height;
          }
        } @else {
          .#{$modifier-name} {
            @include _(#{$modifier-name});
          }

          @include placeholder("#{$modifier-name}") {
            font-size: $font-size;
            line-height: $line-height;
          }
        }
      }
    }

    p {
      font-size: $base-font-size;
      line-height: $base-line-height;
    }
  }

  @if $font-weight-modifiers != null {
    @each $modifier-name, $value in $font-weight-modifiers {
      $font-weight: nth($value, 1);

      @if $font-weight-modifier-prefix != null {
        .#{$font-weight-modifier-prefix}#{$modifier-name} {
          @include _(#{$font-weight-modifier-prefix}#{$modifier-name});
        }

        @include placeholder(
          "#{$font-weight-modifier-prefix}#{$modifier-name}"
        ) {
          font-weight: $font-weight;
        }
      } @else {
        .#{$modifier-name} {
          @include _(#{$modifier-name});
        }

        @include placeholder("#{$modifier-name}") {
          font-weight: $font-weight;
        }
      }
    }
  }
}

@mixin line-heights {
  @if $line-height-modifiers != null {
    @each $modifier-name, $value in $line-height-modifiers {
      $line-height: nth($value, 1);

      @if $vertical-rhythm != null {
        .#{$modifier-name} {
          @include _(#{$modifier-name});
        }

        @include placeholder("#{$modifier-name}") {
          line-height: $line-height * $vr-base;
        }
      } @else {
        .#{$modifier-name} {
          @include _(#{$modifier-name});
        }

        @include placeholder("#{$modifier-name}") {
          line-height: $line-height;
        }
      }
    }
  }
}

@include typographic;
