//------------------------------------
// Global Variables and Map Functions
//------------------------------------


// Variable Defaults

$content-width: 100% !default;

$radius: 1em !default;

$global-transition-speed: null !default;
$global-accent-color: null !default;


// Box Model 
//----------------------------------------------------
$box-model: null !default;

@mixin box-sizing () {
	// set box-sizing model
	*, *:before, *:after {
		box-sizing: $box-model;
	}
}

@if $box-model != null {
	@include box-sizing;
}

//Global Text Links
//----------------------------------------------------
$link-styling: null !default;
 
$link-color: inherit !default;
$link-color-hover: inherit !default;
$underline-links: false !default;
$link-hover-style : null !default;
 
@mixin link-styling () {
 @if $link-styling != null {
 
   a {
 
     cursor: pointer;
     color: $link-color;
 
     &:hover {
       color: $link-color-hover;
     }
 
     @if $underline-links != true {
       text-decoration: none;
     }
 
     @if $link-hover-style == transition-color {
       transition: all $global-transition-speed ease-in-out;
     }
   }
 }
}
 
@include link-styling;






