// ---------------------------------------------------
// ******* COLOR PALETTE *********************
//----------------------------------------------------

// Primay Brand Colors
$swatch-1: #c10f3a; // New Brand Red
$swatch-2: #911938; // Dark Brand Red
$light-grey: #f5f5f5; // Light Grey
$medium-grey: #cccccc; // Medium Grey
$dark-grey: #474c55; // Dark Grey
$therm-grey: #4f555e;

// Secondary Brand Colors
$swatch-3: #8332a7; // Light Purple
$swatch-3-w50: #c198d3; // Light Purple W50
$swatch-4: #135CB0; // Light Blue
$swatch-5: #64d0e4; // Light Aqua
$swatch-6: #c0da07; // Light Green, UTD
$swatch-7: #ffc32c; // Light Yellow, CCAM

$swatch-8: #490e67; // Dark Purple
$swatch-9: #002856; // Dark Blue
$swatch-10: #1eadc1; // Dark Aqua
$swatch-11: #75bc21; // Dark Green
$swatch-12: #ffb125; // Dark Yellow

// Monochromatic - greyscale colors
$white: #fff;
$black: #000; // black
$grey-10: lighten($black, 90%); // #e5e5e5
$grey-20: lighten($black, 80%); // #cccccc
$grey-30: lighten($black, 70%); // #b2b2b2
$grey-40: lighten($black, 60%); // #666666
$grey-50: lighten($black, 50%); // #999999
$grey-60: lighten($black, 40%); // #7f7f7f
$grey-70: lighten($black, 30%); // #4c4c4c
$grey-80: lighten($black, 20%); // #333333
$grey-90: lighten($black, 10%); // #1a1a1a

// Additional Colors
$charcoal: #2a2e30; // Charcoal grey

// UI Defaults
$base: $grey-20; // sets a base color for text & UI elements such as inputs, radios, buttons, etc...
$primary: $swatch-1;
$accent: $swatch-4; // sets an accent to the base color used for things like :focus, links, selected, etc..
$inverse: #fff; // sets a light color for dark backgrounds
$error: #e03400;
$error-light: #fbebe6;
$error-dark: #861f00;
$success: #008a1c;
$success-light: #e6f3e8;
$success-dark: #005210;

// Global link color
$link-color: $accent; // accent blue
$link-hover-color: lighten($link-color, 20%);
$link-color-2: $primary; // brand red
$link-hover-color-2: lighten($link-color-2, 20%);

// Social Media Color Swatches
$facebook: #324d8f;
$twitter: #00a1ed;
$google: #db402c;
$youtube: #b50000;
$linkedin: #0077b5;
$twitch: #6441a4;

// Other Colors
$blm-yellow: #fce21b;
