@font-face {
  font-family: eight-bit;
  src: url("../../files/8-BIT-WONDER.TTF");
}

.scoreboard {
  &-home {
    p {
      @include _(fs-xs);
      @include _(mt-xs);
    }
  }
  &-score {
    background-color: $black;

    &.page {
      width: 350px;
      height: 244px;
      margin: 0 auto;
      background: url("../../images/relay_small_scoreboard.jpg") no-repeat;
      background-size: cover;
      background-position: center;
      // border-radius: $radius;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      box-shadow: 8px 8px $grey-50;

      @include breakpoint(medium) {
        width: 580px;
        height: 350px;
        background-size: cover;
      }

      .head_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 275;

        @include breakpoint(medium) {
          width: 380px;
        }
        img {
          width: 60%;
        }
        p {
          @include _(fw-xbold);
          @include _(fs-ml);
          // @include _(mt-xxs);
          color: $white;

          &.page_points {
            @include _(pv-xxs);
            @include _(ph-xs);
            @include _(m-xs);
            background-color: $swatch-12;
            border-radius: $radius;
            border: 3px solid #fff;
            position: relative;
            // min-width: 82px;
            min-width: 82px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include breakpoint(medium) {
              @include _(m-s);
            }

            &:before {
              background: #fff3;
              border-radius: 0.2rem;
              content: "";
              height: 35px;
              left: 0;
              margin: 0 auto;
              position: absolute;
              right: 0;
              top: 3px;
              width: 95%;
            }
          }

          @include breakpoint(medium) {
            font-size: 4.2087269129rem;
            line-height: 3.9375rem;
          }
        }

        div {
          // margin-top: 4rem;
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &.small {
      // width: 400px;
      // height: 225px;
      width: 485px;
      height: 300px;
      background: url("../../images/relay_small_scoreboard.jpg") no-repeat;
      background-size: cover;
      border-radius: $radius;
      position: relative;

      .video-wrapper {
        video {
          object-fit: cover;
          width: 485px;
          height: 300px;
          position: fixed;
          top: 0;
          left: 0;
        }
      }

      .head_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 485px;
        img {
          width: 60%;
        }
        p {
          @include _(fs-ml);
          @include _(fw-xbold);
          // @include _(mt-xxs);

          color: $white;

          &.page_points {
            @include _(pv-xxs);
            @include _(ph-xs);
            @include _(m-s);
            background-color: $swatch-12;
            border-radius: $radius;
            border: 3px solid #fff;
            position: relative;
            &:before {
              background: #fff3;
              border-radius: 0.2rem;
              content: "";
              height: 25px;
              left: 0;
              margin: 0 auto;
              position: absolute;
              right: 0;
              top: 3px;
              width: 95%;
            }
          }
        }

        div {
          // margin-top: 4rem;
          width: 35%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }

      .score {
        &-wrapper {
          width: 300px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          left: 0;
          right: 0;
          top: 2rem;

          &.triple {
            // width: 975px;

            .score {
              width: 125px;
            }
          }

          .score {
            @include _(ph-xs);
            // @include _(pv-s);
            box-shadow: 10px 0 black, -10px 0 black, 0 -10px black, 0 10px black;
            font-family: "8-bit-pusab";
            position: relative;
            width: 90px;
            height: 85px;
            text-align: center;
            background-color: #ffd225;
            padding-top: 2px;

            // &:before {
            //   position: absolute;
            //   top: 0px;
            //   right: 0px;
            //   bottom: 0px;
            //   left: 0px;
            //   content: "";
            //   box-shadow: inset -15px -15px $white;
            // }

            &:after {
              position: absolute;
              top: 0px;
              right: 0px;
              bottom: 0px;
              left: 0px;
              content: "";
              box-shadow: inset -10px -10px color-variant($swatch-12, -2);
            }

            .name {
              @include _(pv-xxs);
              @include _(fs-m);
              @include _(fw-xbold);
              // background-color: $swatch-12;
              background: linear-gradient(
                $swatch-12 60%,
                color-variant($swatch-12, -2)
              );
            }

            .points {
              @include _(fs-xxl);
              @include _(fw-xbold);
              // @include _(pt-m);
              @include _(mt-none);
              font-family: "eight-bit";
              // background-color: $dark-grey;
              // background: linear-gradient($dark-grey, $grey-90);
              color: $black;
              // border: 1px solid $grey-20;
              border-radius: $radius;
              min-width: 50%;
              // height: 220px;
              position: relative;
              font-size: 2rem !important;
              // padding: 4rem 0;
            }
          }
        }
      }

      .fight {
        &-wrapper {
          position: relative;
          height: 300px;

          .verse {
            // @include _(fs-xxl);
            @include _(fw-xbold);
            @include _(pv-s);
            font-family: "eight-bit";
            border: 3px solid $white;
            background-color: $black;
            background: linear-gradient($grey-90, $grey-80);
            position: absolute;
            color: $white;
            // width: 200px;
            width: 125px;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: 125px;
            text-align: center;
            // transform: skewX(340deg);
            font-size: 2.5rem !important;
          }

          .fight {
            // width: 25%;
            // height: 693px;
            position: absolute;

            &.myke {
              // transform: translateY(20px) translateX(-167px);
              // animation: stephen 3s ease-in-out infinite;
              width: 24%;
              bottom: -10px;
              right: 75px;
              // transform: translateX(295px) translateY(-77px);

              &.jump {
                width: 32%;
                bottom: -15px;
                right: 63px;
                // transform: translateX(267px) translateY(-115px);
              }
            }

            @keyframes myke {
              0% {
                transform: translateY(20px) translateX(-167px);
                // transform: translateY(235px) translateX(-167px);
              }
              50% {
                transform: translateY(25px) translateX(-175px);
                // transform: translateY(240px) translateX(-175px);
              }
              100% {
                transform: translateY(20px) translateX(-167px);
                // transform: translateY(235px) translateX(-167px);
              }
            }

            &.stephen {
              // transform: translateX(1350px) translateY(-570px);
              // animation: myke 4s 1s ease-in-out infinite;
              width: 25%;
              left: 72px;
              bottom: -10px;
              // transform: translateY(113px) translateX(75px);

              &.jump {
                // transform: translateY(76px) translateX(58px);
                left: 58px;
                bottom: -15px;
                width: 33%;
              }
            }

            @keyframes stephen {
              0% {
                transform: translateX(1350px) translateY(-570px);
                // transform: translateX(1300px) translateY(-359px);
              }
              50% {
                transform: translateX(1360px) translateY(-581px);
                // transform: translateX(1310px) translateY(-370px);
              }
              100% {
                transform: translateX(1350px) translateY(-570px);
                // transform: translateX(1300px) translateY(-359px);
              }
            }
          }
        }
      }
    }

    &.large {
      width: 1920px;
      height: 1080px;
      overflow: hidden;
      // background: url("../../images/relay_scoreboard_bg.jpg") no-repeat;
      background-color: #000;
      .video-wrapper {
        video {
          object-fit: cover;
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
        }
      }
      .header {
        width: 50%;
        margin: 0 auto;
        text-align: center;
        visibility: hidden;

        h1 {
          @include _(mt-m);
          @include _(fs-xxl);
          @include _(fw-xbold);
          color: $white;
        }

        p {
          @include _(fs-l);
          @include _(mv-s);
          color: $white;
        }
      }
      .score {
        &-wrapper {
          width: 800px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          left: 0;
          right: 0;
          top: 18rem;

          &.triple {
            width: 975px;

            .score {
              width: 459px;
            }
          }

          .score {
            @include _(ph-xs);
            @include _(pv-s);
            box-shadow: 15px 0 black, -15px 0 black, 0 -15px black, 0 15px black;
            font-family: "8-bit-pusab";
            position: relative;
            width: 325px;
            text-align: center;
            background-color: #ffd225;

            // &:before {
            //   position: absolute;
            //   top: 0px;
            //   right: 0px;
            //   bottom: 0px;
            //   left: 0px;
            //   content: "";
            //   box-shadow: inset -15px -15px $white;
            // }

            &:after {
              position: absolute;
              top: 0px;
              right: 0px;
              bottom: 0px;
              left: 0px;
              content: "";
              box-shadow: inset -15px -15px color-variant($swatch-12, -2);
            }

            .name {
              @include _(pv-xxs);
              @include _(fs-m);
              @include _(fw-xbold);
              // background-color: $swatch-12;
              background: linear-gradient(
                $swatch-12 60%,
                color-variant($swatch-12, -2)
              );
            }

            .points {
              @include _(fs-xxl);
              @include _(fw-xbold);
              // @include _(pt-m);
              @include _(mt-none);
              font-family: "eight-bit";
              // background-color: $dark-grey;
              // background: linear-gradient($dark-grey, $grey-90);
              color: $black;
              // border: 1px solid $grey-20;
              border-radius: $radius;
              min-width: 50%;
              height: 220px;
              position: relative;
              font-size: 8rem !important;
              padding: 4rem 0;
            }
          }
        }
      }

      .fight {
        &-wrapper {
          position: relative;

          .verse {
            // @include _(fs-xxl);
            @include _(fw-xbold);
            @include _(pv-s);
            font-family: "eight-bit";
            border: 3px solid $white;
            background-color: $black;
            background: linear-gradient($grey-90, $grey-80);
            position: absolute;
            color: $white;
            // width: 200px;
            width: 125px;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: 125px;
            text-align: center;
            // transform: skewX(340deg);
            font-size: 2.5rem !important;
          }

          .fight {
            width: 25%;
            height: 693px;
            // position: absolute;

            &.myke {
              // transform: translateY(20px) translateX(-167px);
              // animation: stephen 3s ease-in-out infinite;
              transform: translateX(1350px) translateY(-609px);

              &.jump {
                width: 34%;
                transform: translateX(1263px) translateY(-742px);
              }
            }

            @keyframes myke {
              0% {
                transform: translateY(20px) translateX(-167px);
                // transform: translateY(235px) translateX(-167px);
              }
              50% {
                transform: translateY(25px) translateX(-175px);
                // transform: translateY(240px) translateX(-175px);
              }
              100% {
                transform: translateY(20px) translateX(-167px);
                // transform: translateY(235px) translateX(-167px);
              }
            }

            &.stephen {
              // transform: translateX(1350px) translateY(-570px);
              // animation: myke 4s 1s ease-in-out infinite;
              transform: translateY(84px) translateX(55px);

              &.jump {
                transform: translateY(-40px) translateX(-11px);
                width: 33%;
              }
            }

            @keyframes stephen {
              0% {
                transform: translateX(1350px) translateY(-570px);
                // transform: translateX(1300px) translateY(-359px);
              }
              50% {
                transform: translateX(1360px) translateY(-581px);
                // transform: translateX(1310px) translateY(-370px);
              }
              100% {
                transform: translateX(1350px) translateY(-570px);
                // transform: translateX(1300px) translateY(-359px);
              }
            }
          }
        }
      }

      .glow-box {
        position: absolute;
        bottom: -70px;
        width: 1920px;
        height: 100px;
        filter: blur(25px);
        background: -webkit-gradient(
          linear,
          right top,
          left top,
          from(#6501de),
          color-stop(#ff5770),
          to(#6501de)
        );
        background: linear-gradient(to left, #6501de, #ff5770, #6501de);
        background-size: 200% 200%;
        animation: animateGlow 5s linear infinite;

        @keyframes animateGlow {
          0% {
            background-position: 0% 50%;
          }
          100% {
            background-position: 200% 50%;
          }
        }
      }
    }
  }
  &-ticker {
    @include _(mt-s);
    .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;

      h1 {
        @include _(fw-xbold);
        @include _(mt-xs);
        @include _(mb-s);
        color: $grey-90;
        line-height: 1rem;
      }

      .container {
        //   @include _(m-xs);
        @include _(p-xs);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-50;
        width: 300px;
        text-align: center;
        background-color: $grey-10;

        &.stephen {
          margin-right: 5px;
        }
        &.myke {
          margin-left: 5px;
        }

        p {
          @include _(fs-l);
          @include _(fw-xbold);
          background-color: $white;
          border: 1px solid $grey-20;
          border-radius: $radius;
          min-width: 50%;
        }
      }

      button {
        @include _(p-none);
        @include _(fw-xbold);
        @include _(fs-m);
        background: transparent;
        border: 0;
        cursor: pointer;
      }
    }

    .button-wrapper {
      @include _(mt-s);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        @include _(p-xs);
        @include _(fs-ms);
        border-radius: $radius;
        background-color: $link-color;
        border: 1px solid $link-hover-color;
        width: 200px;
        cursor: pointer;
        color: $white;
        &:hover {
          background-color: $link-hover-color;
        }
      }
    }
  }
}
