//------------------------------------
// Map to create depth modifiers
//------------------------------------

@mixin box-shadows {
  $box-shadow-map: null !default;

  @if $box-shadow-map != null {

    @each $box-shadow-name, $shadow-depth in $box-shadow-map {

      @include placeholder('#{$box-shadow-name}') {
        box-shadow: $shadow-depth;
      } 
    }
  }
}










