//------------------------------------
// Global Spacing Helpers
//------------------------------------

$global-spacing: null !default;

$margin-uniform-prefix: null !default;
$margin-horizontal-prefix: null !default;
$margin-vertical-prefix: null !default;
$margin-top-prefix: null !default;
$margin-bottom-prefix: null !default;

$padding-uniform-prefix: null !default;
$padding-horizontal-prefix: null !default;
$padding-vertical-prefix: null !default;
$padding-top-prefix: null !default;
$padding-bottom-prefix: null !default;

@mixin global-spacers {

	@if $global-spacing != null {

	//Appends spacing prefixes for margin and padding values
		@each $spacer-name, $spacer in $global-spacing {
			$padding: nth($spacer, 1);
			$margin: nth($spacer, 1);


			@if $margin-uniform-prefix != null {
				.#{$margin-uniform-prefix}-#{$spacer-name} {
				  	@include _(#{$margin-uniform-prefix}-#{$spacer-name});
				} 
				@include placeholder('#{$margin-uniform-prefix}-#{$spacer-name}') {
					margin: #{$margin}rem;
				}
			}

			@if $margin-horizontal-prefix != null {
				.#{$margin-horizontal-prefix}-#{$spacer-name} {
				  @include _(#{$margin-horizontal-prefix}-#{$spacer-name});
				} 

				@include placeholder('#{$margin-horizontal-prefix}-#{$spacer-name}') {
					margin-left: #{$margin}rem;
					margin-right: #{$margin}rem;
				}
			}

			@if $margin-vertical-prefix != null {
				.#{$margin-vertical-prefix}-#{$spacer-name} {
				  @include _(#{$margin-vertical-prefix}-#{$spacer-name});
				} 
				@include placeholder('#{$margin-vertical-prefix}-#{$spacer-name}') {
					margin-top: #{$margin}rem;
					margin-bottom: #{$margin}rem;
				}
			}



			@if $margin-top-prefix != null {
				.#{$margin-top-prefix}-#{$spacer-name} {
					@include _(#{$margin-top-prefix}-#{$spacer-name});
				}

				@include placeholder('#{$margin-top-prefix}-#{$spacer-name}') {
					margin-top: #{$margin}rem;
				}
			}

			@if $margin-bottom-prefix != null {
				.#{$margin-bottom-prefix}-#{$spacer-name} {
				  @include _(#{$margin-bottom-prefix}-#{$spacer-name});
				}

				@include placeholder('#{$margin-bottom-prefix}-#{$spacer-name}') {
					margin-bottom: #{$margin}rem;
				}
			}

			@if $padding-uniform-prefix != null {
				.#{$padding-uniform-prefix}-#{$spacer-name} {
				  	@include _(#{$padding-uniform-prefix}-#{$spacer-name});
				} 
				@include placeholder('#{$padding-uniform-prefix}-#{$spacer-name}') {
					padding: #{$padding}rem;
				}
			}

			@if $padding-horizontal-prefix != null {
				.#{$padding-horizontal-prefix}-#{$spacer-name} {
				  @include _(#{$padding-horizontal-prefix}-#{$spacer-name});
				} 

				@include placeholder('#{$padding-horizontal-prefix}-#{$spacer-name}') {
					padding-left: #{$padding}rem;
					padding-right: #{$padding}rem;
				}
			}

			@if $padding-vertical-prefix != null {
				.#{$padding-vertical-prefix}-#{$spacer-name} {
				  @include _(#{$padding-vertical-prefix}-#{$spacer-name});
				} 
				@include placeholder('#{$padding-vertical-prefix}-#{$spacer-name}') {
					padding-top: #{$padding}rem;
					padding-bottom: #{$padding}rem;
				}
			}

			@if $padding-top-prefix != null {
				.#{$padding-top-prefix}-#{$spacer-name} {
				  	@include _(#{$padding-top-prefix}-#{$spacer-name});
				} 
				@include placeholder('#{$padding-top-prefix}-#{$spacer-name}') {
					padding-top: #{$padding}rem;
				}
			}

			@if $padding-bottom-prefix != null {
				.#{$padding-bottom-prefix}-#{$spacer-name} {
				  	@include _(#{$padding-bottom-prefix}-#{$spacer-name});
				} 
				@include placeholder('#{$padding-bottom-prefix}-#{$spacer-name}') {
					padding-bottom: #{$padding}rem;
				}
			}

		}

	}
}









