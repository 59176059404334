body > header {
  @include _(full-width);

  .cta-links {
    @include _(fs-xs);

    @include breakpoint(medium) {
      @include _(mh-xs);
    }

    a {
      @include breakpoint(medium) {
        color: $grey-90;
        @include _(text-uppercase);
        @include _(mh-xxs);

        &:hover {
          color: $grey-40;
        }
      }
    }
  }
}

header {
  .banner {
    @include _(ph-xs);
    transition: height linear $global-transition-speed;
    position: relative;
    height: $vr-base * 2rem;
    cursor: auto;
    background: rgba(0, 0, 0, 0.9);

    @include flex-row(
      $align-columns-horizontal: flex-start,
      $align-columns-vertical: flex-middle
    );

    @include breakpoint(medium) {
      height: $vr-base * 2.5rem;
    }

    .ccam-ribbon {
      @include _(mh-xxs);
      @include _(ph-xs);
      border-left: 1px solid #fff;
      display: flex;
      vertical-align: middle;
      img {
        // margin-top: 0.5rem;
        width: 35px;
      }
    }

    .logo {
      display: block;
      width: 10rem;
      height: 5rem;
      background-repeat: no-repeat;
      background-size: 105%;

      a {
        &:hover {
          text-decoration: none;
        }
      }

      @include breakpoint(medium) {
        // height: $vr-base*2rem;
        width: 12rem;
      }

      // span {
      //     // @include _(visually-hidden);
      // }
    }

    .sjlogo {
      height: $vr-base * 2rem;
      width: 88px;
      position: relative;
      display: inline-block;
      margin-right: 15px;
      text-indent: -9999px;

      span {
        @include _(visually-hidden);
      }

      @media only screen and (max-width: $bp-3) {
        margin-right: 10px;
        width: 65px;
      }

      img {
        height: $vr-base * 2rem;
        max-width: none;
        @include absolutecenter;
      }
    }

    .program-name {
      position: relative;
      display: inline-block;
      height: $vr-base * 2.5rem;

      .logo-subsite-trademark {
        display: initial;
        font-size: 0.55rem;
      }

      @media only screen and (max-width: $bp-7) {
        width: 65%;
      }

      span {
        display: table-cell;
        vertical-align: middle;
        height: $vr-base * 2rem;
        @include _(fs-ms);
        line-height: 1.3rem;

        @media only screen and (max-width: $bp-3) {
          font-size: 1.3em;
        }

        span {
          font-size: 0.75em;
          vertical-align: super;
        }

        &.marathon {
          line-height: 21px;
          @media only screen and (max-width: $bp-3) {
            font-size: 1em;
          }
        }
      }
    }

    &.red {
      .sjlogo {
        @include svg-icon(
          $icon-name: child-white,
          $size: 4.5rem,
          $color: $swatch-1,
          $stroke-color: null,
          $stroke-width: null
        );
      }

      .program-name {
        span {
          color: $white;
        }
      }
    }
  }
}
