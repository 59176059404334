.footer {
  background: $grey-80;
  @include _(p-xs);
  @include _(text-center);
  @include _(mt-none);

  &__copyright-notice {
    @include _(fs-xs);
    @include _(mv-xxs);
    color: $grey-30;
  }
}

.footer-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: 0 auto;
  margin-left: 2rem;
  max-width: 90%;
  @include _(mb-xs);
  @include _(p-none);
  @include _(text-center);

  &__link-wrapper {
    display: inline-block;
    margin-right: 1rem;
    padding: 0.25rem;
  }

  &__link {
    @include _(fs-xs);
    color: $white;
    text-decoration: underline;
  }
}
