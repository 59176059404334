//------------------------------------
// Layout
//------------------------------------

$content-max-width: null !default;
$content-width: null !default;
$full-width-class-name: null !default;


@include placeholder('content-wrap') {
    max-width: $content-max-width;
    width: $content-width;
    margin-left: auto;
    margin-right: auto;
}

@if $full-width-class-name != null {
    .#{$full-width-class-name} {
        @include _(full-width);
    }
}

@mixin constrain-content (
    $content-max-width: none
) {
    max-width: $content-max-width;
    @include _(center-content);
}

@mixin offset-content (
    $offset-direction: null,
    $offset-value: null
) {
    position: relative;
    z-index: 2;

    @if $offset-direction == top {
        margin-top: $offset-value;
    }

    @if $offset-direction == bottom {
        margin-bottom: $offset-value;
    }

    @if $offset-direction == left {
        margin-left: $offset-value;
    }

    @if $offset-direction == right {
        margin-right: $offset-value;
    }
}

// Flexbox Row
//------------------------------------

// Create a Flex Row 
@include placeholder('flex-row') {
    display: flex;
    flex-wrap: wrap;
}

// Creating flex column alignment options
//------------------------------------------

// horizontal alignment
//----------------------

// align items left
@include placeholder('flex-left') {
    justify-content: flex-start;
}

// align items right
@include placeholder('flex-right') {
    justify-content: flex-end;
}

// align items center
@include placeholder('flex-center') {
    justify-content: center;
}

// distribute space between items evenly
@include placeholder('space-between') {
    justify-content: space-between;
}

// distribute space around items evenly
@include placeholder('space-around') {
    justify-content: space-around;
}

// vertical alignment
//--------------------

// align items vertical top
@include placeholder('flex-top') {
    align-items: flex-start;
}

// align items vertical bottom
@include placeholder('flex-bottom') {
    align-items: flex-end;
}

// align items vertical middle
@include placeholder('flex-middle') {
    align-items: center;
}

// Make items fill vertical space
@include placeholder('flex-stretch') {
    align-items: stretch;
}



// Flexbox Column
//------------------------------------

// align self overrides
//----------------------

// position column vertical top
@include placeholder('align-column-top') {
    align-self: flex-start;
}

// position column vertical bottom
@include placeholder('align-column-bottom') {
    align-self: flex-end;
}

// position column vertical middle
@include placeholder('align-column-middle') {
    align-self: center;
}

// make column fill vertical space
@include placeholder('align-column-stretch') {
    align-self: stretch;
}


// Flex No-Wrap placeholder for overwriting "wrap" default 
//-----------------------------------------------------

@include placeholder('flex-no-wrap') {
    flex-wrap: nowrap;
}

//Flex Order Mixins
//-----------------------------------------------------

// Set default flex order for elements
@mixin default-flex-order {

    @if $generate-grid-classes != false {
        order: $grid-columns;
    }
}

// Flex Order
@mixin flex-order (
    $flex-order: null
) {
    order: $flex-order;
}
    


// Flex row mixin
//-----------------------------------------------------
@mixin flex-row (
    $flex-direction: row,
    $align-columns-horizontal: space-around,
    $align-columns-vertical: flex-stretch
) {

    //loads flexbox base styles
    @include _(flex-row);


    // sets axis direction for columns
    @if $flex-direction == row {
        flex-direction: row;
    }

    @if $flex-direction == column {
        flex-direction: column;
    }


    // load horizontal alignment placeholder options 
    @if $align-columns-horizontal == flex-left {
        @include _(flex-left);
    }

    @if $align-columns-horizontal == flex-right {
        @include _(flex-right);
    }

    @if $align-columns-horizontal == flex-center {
        @include _(flex-center);
    }

    @if $align-columns-horizontal == space-between {
        @include _(space-between);
    }

    @if $align-columns-horizontal == space-around {
        @include _(space-around);
    }

    // load vertical alignment placeholder options 
    @if $align-columns-vertical == flex-top {
        @include _(flex-top);
    }

    @if $align-columns-vertical == flex-bottom {
        @include _(flex-bottom);
    }

    @if $align-columns-vertical == flex-middle {
        @include _(flex-middle);
    }

    @if $align-columns-vertical == flex-stretch {
        @include _(flex-stretch);
    }

}

//Flex column mixin
//-----------------------------------------------------
@mixin flex-col (
    $align-column: null
) {
    
    @include _(mt-none);
    // @include default-flex-order;

   // load vertical alignment placeholder options for columns 
    @if $align-column == top {
        @include _(align-column-top);
    }

    @if $align-column == bottom {
        @include _(align-column-bottom);
    }

    @if $align-column == middle {
        @include _(align-column-middle);
    }

    @if $align-column == stretch {
        @include _(align-column-stretch);
    }    

}







