//------------------------------------
// RESET
//------------------------------------

// options are 'null', 'normalize' or 'simple'

$reset-type: null !default; 

@mixin reset {


    // SIMPLE - this zeros out all styles for everything
    //----------------------------------------------------

    @if $reset-type == simple {
      // Reset all the things
      html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }
      article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; }
      html, body { height: 100%; }
      a img { border: none; }
      blockquote { quotes: none; }
      blockquote:before, blockquote:after { content: ''; content: none; }
      table { border-collapse: collapse; border-spacing: 0; }
      caption, th, td { text-align: left; font-weight: normal; vertical-align: middle; }

    }


    // NORMALIZE
    //----------------------------------------------------

    @if $reset-type == normalize {

        html {
          -ms-text-size-adjust: 100%; /* 3 */
          -webkit-text-size-adjust: 100%; /* 3 */
        }

        body {
          margin: 0;
        }

        article,
        aside,
        footer,
        header,
        nav,
        section {
          display: block;
        }


        figcaption,
        figure,
        main { /* 1 */
          display: block;
          margin: 0;
        }

        hr {
          // box-sizing: content-box; /* 1 */
          height: 0; /* 1 */
          overflow: visible; /* 2 */
        }

        pre {
          font-family: monospace, monospace; /* 1 */
          font-size: 1em; /* 2 */
        }

        a {
          background-color: transparent; /* 1 */
          -webkit-text-decoration-skip: objects; /* 2 */
        }

        a:active,
        a:hover {
          outline-width: 0;
        }

        abbr[title] {
          border-bottom: none; /* 1 */
          text-decoration: underline; /* 2 */
          text-decoration: underline dotted; /* 2 */
        }

        b,
        strong {
          font-weight: inherit;
        }

        b,
        strong {
          font-weight: bolder;
        }

        code,
        kbd,
        samp {
          font-family: monospace, monospace; /* 1 */
          font-size: 1em; /* 2 */
        }

        dfn {
          font-style: italic;
        }

        mark {
          background-color: #ff0;
          color: #000;
        }

        small {
          font-size: 80%;
        }

        sub,
        sup {
          font-size: 75%;
          line-height: 0;
          position: relative;
          vertical-align: baseline;
        }

        sub {
          bottom: -0.25em;
        }

        sup {
          top: -0.5em;
        }

        audio,
        video {
          display: inline-block;
        }

        audio:not([controls]) {
          display: none;
          height: 0;
        }

        img {
          border-style: none;
        }

        svg:not(:root) {
          overflow: hidden;
        }

        button,
        input,
        optgroup,
        select,
        textarea {
          font-family: sans-serif; /* 1 */
          font-size: inherit; /* 1 */
          margin: 0; /* 2 */
        }

        button,
        input { /* 1 */
          overflow: visible;
        }

        button,
        select { /* 1 */
          text-transform: none;
        }

        button,
        html [type="button"], /* 1 */
        [type="reset"],
        [type="submit"] {
          -webkit-appearance: button; /* 2 */
        }

        button::-moz-focus-inner,
        [type="button"]::-moz-focus-inner,
        [type="reset"]::-moz-focus-inner,
        [type="submit"]::-moz-focus-inner {
          border-style: none;
          padding: 0;
        }

        button:-moz-focusring,
        [type="button"]:-moz-focusring,
        [type="reset"]:-moz-focusring,
        [type="submit"]:-moz-focusring {
          outline: 1px dotted ButtonText;
        }

        legend {
          // box-sizing: border-box; /* 1 */
          color: inherit; /* 2 */
          display: table; /* 1 */
          max-width: 100%; /* 1 */
          padding: 0; /* 3 */
          white-space: normal; /* 1 */
        }

        progress {
          display: inline-block; /* 1 */
          vertical-align: baseline; /* 2 */
        }

        textarea {
          overflow: auto;
        }


        [type="checkbox"],
        [type="radio"] {
          // box-sizing: border-box; /* 1 */
          padding: 0; /* 2 */
        }

        [type="number"]::-webkit-inner-spin-button,
        [type="number"]::-webkit-outer-spin-button {
          height: auto;
        }


        [type="search"] {
          -webkit-appearance: textfield; /* 1 */
          outline-offset: -2px; /* 2 */
        }

        [type="search"]::-webkit-search-cancel-button,
        [type="search"]::-webkit-search-decoration {
          -webkit-appearance: none;
        }

        ::-webkit-file-upload-button {
          -webkit-appearance: button; /* 1 */
          font: inherit; /* 2 */
        }

        details, /* 1 */
        menu {
          display: block;
        }

        summary {
          display: list-item;
        }

        canvas {
          display: inline-block;
        }

        template {
          display: none;
        }

        [hidden] {
          display: none;
        }
    }

}

// load reset types
@include reset;