@mixin heading-1 {
  @include _(fw-light);
  color: $swatch-8;
  font-size: 32px;
  line-height: 48px;

  @include breakpoint(medium) {
    font-size: 41px;
    line-height: 61px;
  }
}

@mixin heading-2 {
  @include _(fw-light);
  color: $swatch-3;
  font-size: 34px;
  line-height: 43px;

  @include breakpoint(medium) {
    line-height: 51px;
  }
}

@mixin heading-3 {
  @include _(fw-light);
  font-size: 21px;
  line-height: 32px;

  @include breakpoint(large) {
    font-size: 27px;
    line-height: 41px;
  }

  &.light {
    color: $white;
  }
}

@mixin heading-4 {
  @include _(fw-xbold);
  font-size: 16px;
  line-height: 41px;

  @include breakpoint(large) {
    font-size: 18px;
  }
}

@mixin paragraph {
  @include _(fw-light);
  font-size: 16px;
  line-height: 25px;

  @include breakpoint(medium) {
    font-size: 18px;
  }
}
