.overlay_lp {
  &__top {
    background: $dark-grey;
  }

  .hero {
    height: 225px;
    position: relative;
    // border-bottom: 3px solid $swatch-1;
  }

  .legal {
    @include _(fs-xs);
    width: 80%;
    margin: 0 auto;
  }

  .hero-slant {
    height: 90px;
    transform: skewY(-2deg);
    background-color: $white;
    position: relative;
    bottom: 45px;
    border-top: 5px solid $swatch-1;

    &.gt-movie {
      border-top: 5px solid #ff0000;
    }
    &.gt-food {
      border-top: 5px solid #ffff00;
    }
    &.gt-gaming {
      border-top: 5px solid #00e51f;
    }
    &.gt-jb {
      border-top: 5px solid #eec400;
    }
  }

  &__content {
    // @include _(pb-xs);
    width: 80%;
    margin: 0 auto;

    @include breakpoint(medium) {
      // width: 80%;
      // height: 800px;
      // margin: 0 auto;
    }

    h2 {
      @include _(fs-ml);
      @include _(fw-bold);
      color: $grey-90;
      text-align: center;
    }
    p {
      color: $grey-90;
    }

    &__thumbnail {
      width: 100%;
    }

    &__left {
      &.artifact {
        margin-top: 0 !important;
        margin-bottom: 2rem;
        margin-left: 0 !important;
        text-align: center;

        @include breakpoint(medium) {
          text-align: left;
        }

        .artifact_img {
          width: 80%;
        }
      }

      &.chatbot {
        text-align: center;

        .chatbot__gif {
          width: 60%;
          border-radius: $radius;
        }
      }
    }

    &__right {
      &.artifact {
        margin-top: 0 !important;
        p {
          @include _(fs-ms);
          color: $white;
        }
      }

      .hide {
        display: none;
      }

      h2 {
        color: $grey-80;
      }

      img {
        display: block;
        width: 100%;
        margin: 0 auto;
        @include breakpoint(medium) {
          width: 80%;
        }

        &.gt-icon {
          @include breakpoint(medium) {
            width: 20%;
          }
        }

        &.minimal-icon {
          @include breakpoint(medium) {
            width: 40%;
          }
        }

        &.alert-icon {
          width: 60%;
        }

        &.max-icon {
          width: 90%;
        }

        &.chatbot-icon {
          width: 25%;
        }

        &.hype-icon {
          width: 50%;
        }

        &.leaderboard-icon {
          width: 25%;
        }

        &.instagram-icon {
          width: 25%;
        }

        &.bundle-icon {
          width: 20%;
        }

        &.rewards-icon {
          width: 20%;
        }

        &.milestones-icon {
          width: 25%;
        }
      }

      p {
        @include _(mv-s);
        @include _(fs-s);
        color: $grey-80;
      }

      h3 {
        @include _(fs-m);
        color: $grey-80;
      }

      ul {
        @include _(mt-xxs);
        @include _(mb-s);

        li {
          position: relative;
          &:before {
            content: " ";
            position: absolute;
            left: -20px;
            top: 10px;
            @include svg-icon(
              $icon-name: check-green,
              $size: 1.5rem,
              $color: $swatch-1,
              $stroke-color: null,
              $stroke-width: null
            );
          }
          list-style-type: none;
          text-align: left;
        }

        &.normal_list {
          li {
            list-style-type: decimal;
            padding-left: 10px;
            &:before {
              content: "";
              display: none;
            }
          }
        }
      }

      a [class*="cta-"].cta-secondary {
        color: $white;
        border: 0;
        background: $accent;

        &:hover {
          background: lighten($accent, 10%);
          border-color: dark-accent;
          color: $white;
        }
      }

      &.gt {
        margin-top: 0 !important;
        position: relative;
        top: -50px;

        img {
          @include _(mb-s);
        }

        p {
          @include _(fs-ms);
          @include _(mt-none);
        }

        .cta-secondary {
          @include breakpoint(medium) {
            width: 25%;
          }
        }
      }
    }

    &.rewards {
      div {
        text-align: center;
        position: relative;
      }

      h3 {
        @include _(fs-ms);
        color: $grey-70;
      }

      img {
        border-radius: $radius;
      }

      .sticker_lang {
        @include _(mt-xs);
        position: relative;
        border-radius: $radius;
        background-color: $light-grey;

        &__left {
          width: 50%;
          text-align: center;
          @include breakpoint(medium) {
            width: 120px;
            position: absolute;
            left: 10px;
            top: -20px;
          }
        }
        &__right {
          display: none;
          @include breakpoint(medium) {
            display: block;
            width: 120px;
            position: absolute;
            right: 0;
            top: -20px;
          }
        }

        p {
          @include _(p-xxs);
          @include _(fw-bold);
          width: 75%;
          margin: 0 auto;

          span {
            @include _(fs-xs);
            display: block;
            font-style: italic;
          }
        }
      }

      .prizes__amount {
        @include _(ph-xs);
        @include _(pv-xxs);
        @include _(fs-ms);
        @include _(fw-bold);
        position: absolute;
        top: 1rem;
        background-color: green;
        color: $white;
        border-radius: $radius;
      }

      img {
        max-width: 100%;
        text-align: center;
      }
      .item {
        max-width: 70%;
      }
    }

    &.rules {
      @include _(mv-l);

      ul {
        li {
          list-style-type: disc;
          &:before {
            display: none;
          }
        }
      }
      // ul {
      //     @include _(mt-xxs);
      // }
      // p, li {
      //     @include _(fs-xs);
      //     @include _(mt-xxs);                }
    }
  }

  &__steps_wrapper {
    // @include _(mv-ms);
    @include _(pb-s);
    @include _(pt-xs);
    background: $grey-10;
    position: relative;

    @include breakpoint(medium) {
      @include _(pb-none);
    }

    &:after {
      content: "";
      @include _(pb-s);
      position: absolute;
      display: none;
      bottom: -35px;
      transform: rotate(-2deg);
      height: 70px;
      width: 100%;
      background-color: $grey-10;
      border-bottom: 5px solid $primary;

      @include breakpoint(medium) {
        display: block;
      }
    }

    .steps {
      text-align: center;
      &__header {
        @include _(fw-bold);
        @include _(fs-ms);
      }
      &__content {
        @include _(fs-s);
      }
      img {
        width: 15%;
      }
    }

    @include breakpoint(medium) {
      height: 300px;
    }
  }

  .all-challenges {
    @include _(mt-ms);
    @include _(pb-s);
    background-color: $light-grey;
    div {
      text-align: center;
      p {
        @include _(fw-bold);
        @include _(mb-xs);
        // @include _(mt-none);
      }
      h3 {
        margin-top: 0 !important;
      }
    }
    img {
      width: 50%;
    }
  }

  &.creative {
    ul {
      margin-top: 0;
    }

    .overlay-examples {
      width: 80%;
      margin: 0 auto;

      .col-4 {
        margin-top: 0;
      }
    }

    .overlay_lp__content {
      @include _(mv-m);
      @include _(pv-m);
      width: 100%;
      background-color: $light-grey;
    }

    .overlay_lp__content__left {
      margin-top: 0;
    }

    .overlay_lp__content__right {
      margin-top: 0;
      p {
        margin-top: 0;
      }
    }

    .anchor {
      margin-bottom: 30px;
      padding-bottom: 50px;
      border-bottom: 1px solid #cccccc;
      @include _(fs-m);
      h2 {
        margin-bottom: 0;
      }
    }

    .instructions {
      border-top: 0;
      padding-top: 0;
    }
  }

  &.ccam {
    ul {
      margin-top: 0;
    }

    .overlay-examples {
      width: 80%;
      margin: 0 auto;

      .col-4 {
        margin-top: 0;
      }
    }

    .final_link {
      @include _(fw-light);
      width: 80%;
      height: 125px;

      span {
        display: block;
        @include _(fw-bold);
      }

      .col-11 {
        margin-left: 0;
        margin-top: 1rem;
      }

      .col-1 {
        margin-top: 1rem;
      }

      input {
        @include _(fs-ms);
        @include _(p-xs);
        height: 48px;
        width: 100%;
        margin-right: 1.5rem;
      }
    }

    // .overlay_lp__top {
    //     // background: $grey-90;
    //     // background-image: none;
    // }

    .overlay_lp__content {
      @include _(mv-xs);
      @include _(pt-s);
      @include _(pb-m);
      width: 100%;
      background-color: $light-grey;

      h3 {
        margin-top: 1rem;
      }

      // .col-8 {
      //     margin-top: 0;
      // }

      label {
        @include _(fw-light);
        display: block;
        &.error {
          color: $error;
        }
      }
      input.username,
      input.slug {
        @include _(fs-ms);
        @include _(p-xs);
        height: 48px;
        margin-right: 1rem;
        display: block;
        &.error {
          border-color: $error;
          border-radius: $radius;
        }
      }

      .cta-secondary {
        width: 300px;
      }
    }

    .overlay_lp__content__left {
      margin-top: 40px;
    }

    .overlay_lp__content__right {
      margin-top: 40px;
      p {
        margin-top: 0;
      }
    }

    .hero-slant {
      height: 90px;
      transform: skewY(-2deg);
      background-color: $white;
      position: relative;
      bottom: 45px;
      border-top: 5px solid $swatch-7;
    }

    .anchor {
      margin-bottom: 30px;
      padding-bottom: 50px;
      border-bottom: 1px solid #cccccc;
      @include _(fs-m);
      h2 {
        margin-bottom: 0;
      }
    }

    .instructions {
      border-top: 0;
      padding-top: 0;
    }
  }
}

.arexperience {
  @include _(pb-m);
  background-image: url("../../images/hospital-bg_dark.jpg");
  background-size: 1000%;
  // background-position: 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  // box-shadow: inset 0 0 0 1000px rgba(0,0,0,.5);
  @include breakpoint(medium) {
    background-size: cover;
    // background-position: 50%;
  }

  .overlay_lp__top {
    background: transparent;
  }

  &__login {
    .my-campaign__body {
      margin-top: 2rem !important;

      h2 {
        text-align: left;
      }

      img {
        display: inline-block;
      }

      .overlay_link__tiltifyconnect {
        width: 100%;
      }

      .launch-mycampaign {
        width: 100%;
        display: block;
        span {
          height: 60px;
          line-height: 2rem;
        }
      }
    }

    .my-campaign__header {
      background: rgba(0, 0, 0, 0.5);

      .program-name {
        span {
          color: $white;
        }
      }
      .sjlogo {
        height: $vr-base * 2rem;
        width: 88px;
        position: relative;
        display: inline-block;
        margin-right: 15px;
        text-indent: -9999px;

        @include svg-icon(
          $icon-name: child-white,
          $size: 4.5rem,
          $color: $swatch-1,
          $stroke-color: null,
          $stroke-width: null
        );

        span {
          @include _(visually-hidden);
        }

        @media only screen and (max-width: $bp-3) {
          margin-right: 10px;
          width: 65px;
        }

        img {
          height: $vr-base * 2rem;
          max-width: none;
          @include absolutecenter;
        }
      }
    }
  }

  header {
    background: rgba(0, 0, 0, 0.5);
  }

  .hero {
    @include _(pv-ms);
    height: auto;
  }
}
