//------------------------------------
// Utility Classes
//------------------------------------

// load placeholder generating maps into placeholder partial
@include global-spacers;
@include box-shadows;
@include line-heights;
@include accent-border-colors;


// Overflow Scroll Helper
//------------------------------------------------------

@include placeholder('overflow-scroll-y') {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

// Normalize (<button>)
@include placeholder('normalize-button') {
    font: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}

// layout Modifiers
//------------------------------------------------------

@include placeholder('full-width') {
  max-width: none;
  width: 100%;
}

@include placeholder('full-height') {
  min-height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
}

// Use on non-flex containers
@include placeholder('center-content') {
  margin-left: auto;
  margin-right: auto;
}

@include placeholder('push-right') {
    margin-left: auto;
    margin-right: calc((100% - #{$content-width}) / 2);
}

@include placeholder('push-left') {
    margin-right: auto;
    margin-left: calc((100% - #{$content-width}) / 2);
}

@include placeholder('clearfix') {
    &:after {
    display: block;
    content: "";
    clear: both;
  }
}



// Design Modifiers || Patterns
//------------------------------------------------------

@include placeholder('round-corners') {
  border-radius: $radius;
}

// Text Alignments
@include placeholder('text-left') {
  text-align: left;
}

@include placeholder('text-right') {
  text-align: right;
}

@include placeholder('text-center') {
  text-align: center;
}

// Text Modifiers
@include placeholder('text-uppercase') {
  text-transform: uppercase;
}

@include placeholder('no-wrap') {
 display: inline-block;
 white-space: nowrap;
}

@include placeholder('make-circle') {
  border-radius: 50%;
  text-align: center;
} 

// Interactions
//------------------------------------------------------
@include placeholder('ripple') {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10, 10);
      opacity: 0;
      transition: transform .5s, opacity 1s;
    }

    &:active:after {
      transform: scale(0, 0);
      opacity: .2;
      transition: 0s;
    }
}


//global hover placeholder

@include placeholder('hover') {
  transition: all $global-transition-speed ease-in-out;
  cursor: pointer;
}

// Accessibility

@include placeholder('skip-nav') {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;

	&:active,
  &:focus,
  &:hover {
      position: relative;
			top: auto;
			left: auto;
			height: auto;
			width: auto;
  }
}
