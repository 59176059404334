//------------------------------------
// Visibility Modifier
//------------------------------------

// Visually Hidden Placeholder
@include placeholder('visually-hidden') {
  border: 0; 
  clip: rect(0 0 0 0); 
  height: 1px; 
  margin: -1px; 
  overflow: hidden; 
  padding: 0; 
  position: absolute; 
  width: 1px; 
}

// placeholder for fading elements opacity in-out

@include placeholder('opacity-fade') {
  opacity: 0;
  visibility: hidden;
  transition: opacity $global-transition-speed linear, visibility $global-transition-speed linear;
}

@mixin opacity-fade (
	$active-class: null,
	$active-opacity-value: null
){
	@include _(opacity-fade);

	@at-root #{$active-class} {

		visibility: visible;

		@if $active-opacity-value != null {
			opacity: $active-opacity-value;
		}

		@else {
			opacity: 1;
		}
	}

}