//------------------------------------
// Button Pattern
//------------------------------------
$btn-style: null !default;

$solid-buttons: null !default;
$hollow-buttons: null !default;

$btn-solid-hover-style: null !default;
$btn-hollow-hover-style: null !default;

$btn-border-base-size: null !default;
$btn-border-base-style: null !default;
$btn-border-ext-style: null !default;



// buttons functions
//-------------------------------------------

@if $solid-buttons != null {

 @each $name, $colors in $solid-buttons {
   $bg-color: nth($colors, 1);
   $border-color: nth($colors, 2);
   $font-color: nth($colors, 3);

   // Button Solid Base Styles
   .#{$name} {
     background: $bg-color;
     border: $btn-border-base-size $btn-border-base-style;
     border-color: $border-color;
     color: $font-color;
     line-height: inherit;
     cursor: pointer;

     //reset styles
     // &:focus {
     //   outline: 0;
     // }

     // Button Solid Hover Styles
     @if $btn-solid-hover-style == simple-fade {
       $hover-bg-color: nth($colors, 4);

       transition: all $global-transition-speed ease-in-out;

       @if $target-non-touch-devices == true {

         .#{$no-touch-class-name} & {
           &:hover {
             background: $hover-bg-color;
             color: $font-color;
           }
         }

       }

       @else {
         &:hover {
             background: $hover-bg-color;
             color: $font-color;
         }
       }

     }


     @if $btn-border-ext-style == thick-bottom {
       border-bottom-width: $btn-border-base-size*3;
       border-bottom-color: darken($border-color, 10%);
     }


   }
 }
}

@if $hollow-buttons != null {

 @each $name, $colors in $hollow-buttons {
   $border-color: nth($colors, 1);
   $font-color: nth($colors, 2);

   .#{$name} {
     background: transparent;
     color: $font-color;

     // Simple fill background color change
     @if $btn-hollow-hover-style == simple-fade {
       $hover-bg-color: nth($colors, 3);
       $hover-font-color: nth($colors, 4);

       transition: all $global-transition-speed ease-in-out;
       border: $btn-border-base-size $btn-border-base-style;
       border-color: $border-color;

       @if $target-non-touch-devices == true {

         .#{$no-touch-class-name} & {
           &:hover {
             background: $hover-bg-color;
             color: $hover-font-color;
           }
         }

       }

       @else {
         &:hover {
           background: $hover-bg-color;
           color: $hover-font-color;
         }
       }

     }

     // Simple border color change and animation
     @if $btn-hollow-hover-style == animate-border-color {
       $hover-border-color: nth($colors, 3);
       $hover-font-color: nth($colors, 4);

       box-shadow: inset 0 0 0 $btn-border-base-size $border-color;
       transition: color $global-transition-speed*1.5 $global-transition-speed;
       position: relative;

       &::before,
       &::after {
         border: 0 solid transparent;
         content: '';
         pointer-events: none;
         position: absolute;
         width: 0;
         height: 0;
         bottom: 0;
         right: 0;
         border-radius: $radius;
       }

       &::before {
         border-bottom-width: $btn-border-base-size;
         border-left-width: $btn-border-base-size;
       }

       &::after {
         border-top-width: $btn-border-base-size;
         border-right-width: $btn-border-base-size;
       }

       @if $target-non-touch-devices == true {

         .#{$no-touch-class-name} & {
           &:hover {

             color: $hover-border-color;

             &::before,
             &::after {
               border-color: $hover-border-color;
               transition: border-color 0s, width $global-transition-speed*1.5, height $global-transition-speed*1.5;
               width: 100%;
               height: 100%;
             }

             &::before {
               transition-delay: 0s, 0s, $global-transition-speed*1.5;
             }

             &::after {
               transition-delay: 0s, $global-transition-speed*1.5, 0s;
             }
           }
         }

       }

       @else {
         &:hover {

           color: $hover-border-color;

           &::before,
           &::after {
             border-color: $hover-border-color;
             transition: border-color 0s, width $global-transition-speed*1.5, height $global-transition-speed*1.5;
             width: 100%;
             height: 100%;
           }

           &::before {
             transition-delay: 0s, 0s, $global-transition-speed*1.5;
           }

           &::after {
             transition-delay: 0s, $global-transition-speed*1.5, 0s;
           }


         }
       }


     }

   }
 }
}


//buttons mixin for base styles
//-------------------------------------------

@mixin btn (
  $btn-padding: .5em 2em,
	$btn-radius: $radius,
	$btn-letter-spacing: 1px
) {

	display: inline-block;
	padding: $btn-padding;
	border-radius: $btn-radius;
	letter-spacing: $btn-letter-spacing;
  transition: all $global-transition-speed cubic-bezier(0,0,.2,1);

	@content;
}
