// body {
//   background-color: #333;
// }

//-----------
// Typography
//-----------

h1 {
  @include heading-1;
}

h2 {
  @include heading-2;
}

h3 {
  @include heading-3;
}

h4 {
  @include heading-4;
}

p {
  @include paragraph;
}

.content-wrapper {
  @include _(pb-m);
}

.left {
  float: left;
}

.right {
  float: right;
}


.b_em {
  @include _(fw-bold);
  font-style: italic;
}

.overlay_link {
  .whatis {
    @include _(fw-medium);
    font-size: 14px;
    margin-left: 15px;
    // padding-bottom: 10px;
    display: inline-block;
    cursor: pointer;
    font-style: italic;
    opacity: 0.65;
    &:hover {
      opacity: 1;
    }
  }
}

.tooltip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;

  &__message {
    @include _(p-m);
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    border-radius: $radius;
  }

  &__close {
    @include _(fs-ms);
    @include _(fw-bold);
    opacity: 0.65;
    position: absolute;
    top: 0px;
    right: 15px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

a {
  // color: $dark-grey;

  [class*="cta-"] {
    @include btn(
      $btn-padding: 0.85rem 2rem,
      $btn-radius: $radius,
      $btn-letter-spacing: null
    ) {
      @include _(depth-level-1);
      @include _(ripple);
      @include _(text-center);
      @include _(lh-s);
      @include _(mt-xs);
      vertical-align: top;
      width: 100%;
      background: $white;

      @include breakpoint(small) {
        + [class*="cta-"] {
          margin-left: 0.5625rem;
        }
      }
    }

    &.cta-primary {
      color: $white;
      background: $primary;
      border-color: $primary;
      border-bottom-color: darken($primary, 10%);
      width: 20%;

      &:hover {
        background: lighten($primary, 10%);
      }
    }

    &.cta-secondary {
      color: $dark-grey;
      border: 1px solid $dark-grey;

      &:hover {
        background: $accent;
        border-color: dark-accent;
        color: $white;
      }
      &.tiltify  {
        background: $dark-grey;
        color: #fff;
        img {
          @include _(mh-xs);
          width: 29px;
        }
        &:hover {
          background: lighten($dark-grey, 10%);
        }
      }
    }
  }
  [class*="cta-embed"] {
    @include btn(
      $btn-padding: 0.7rem .7rem,
      $btn-radius: $radius,
      $btn-letter-spacing: null
    ) {
      @include _(depth-level-1);
      @include _(ripple);
      @include _(text-center);
      @include _(lh-s);
      @include _(mt-xs);
      vertical-align: top;
      width: 100%;
      background: $white;

      @include breakpoint(small) {
        + [class*="cta-"] {
          margin-left: 0.5625rem;
        }
      }
    }

    &.cta-embed-primary {
      color: $white;
      background: $primary;
      border-color: $primary;
      border-bottom-color: darken($primary, 10%);
      width: 20%;

      &:hover {
        background: lighten($primary, 10%);
      }
    }

    &.cta-secondary {
      color: $dark-grey;
      border: 1px solid $dark-grey;

      &:hover {
        background: $accent;
        border-color: dark-accent;
        color: $white;
      }
      &.tiltify  {
        background: $dark-grey;
        color: #fff;
        img {
          @include _(mh-xs);
          width: 29px;
        }
        &:hover {
          background: lighten($dark-grey, 10%);
        }
      }
    }
  }
}

button {
  // color: $dark-grey;

  [class*="cta-"] {
    @include btn(
      $btn-padding: 0.85rem 2rem,
      $btn-radius: $radius,
      $btn-letter-spacing: null
    ) {
      @include _(depth-level-1);
      @include _(ripple);
      @include _(text-center);
      @include _(lh-s);
      @include _(mt-xs);
      vertical-align: top;
      width: 100%;
      background: $white;

      @include breakpoint(small) {
        + [class*="cta-"] {
          margin-left: 0.5625rem;
        }
      }
    }

    &.cta-primary {
      color: $white;
      background: $primary;
      border-color: $primary;
      border-bottom-color: darken($primary, 10%);

      &:hover {
        background: lighten($primary, 10%);
      }
    }

    &.cta-secondary {
      color: $dark-grey;
      border: 1px solid $dark-grey;

      &:hover {
        background: $accent;
        border-color: dark-accent;
        color: $white;
      }
    }
  }
}

// .error {
//   @include _(p-xs);
//   text-align: center;
//   background-color: color-variant($error, 9);
//   color: color-variant($error, -5);
//   border: 1px solid $error;
//   border-radius: $radius;
//   width: 50%;

// }
