// -----------------------------------------------------------
// ******************** MAP CONFIG FILE *********************
//------------------------------------------------------------

// ------------------------------
// Breakpoints - Set Viewports
// ------------------------------
$bp-1: 75em;    //1200px max-width of site content
$bp-2: 60em;    //960px many of the site's elements are set at 950 so this kicks in right before
$bp-3: 48.75em; //780px
$bp-4: 42em;    //672px many of the site's elements are 650px so this kicks in right before
$bp-5: 37.5em;  //600px
$bp-6: 33.125em; //530px
$bp-7: 30em; //480px -- iphone 3/4g landscape is 480px wide
$bp-8: 22.5em; //360px -- iphone is 320px and galaxy is 360px

//--------------------------------------------------
// *********** GLOBAL VARIABLES ************
//--------------------------------------------------

// Reset
//------------------------------------------------
//options = null | simple | normalize
$reset-type: normalize;

// No touch targeting via jQuery, Modernizer, etc...
//-------------------------------------------------

// wraps all hover effects inside of a '.no-touch' class
// when using the include hover mixin
$target-non-touch-devices: true; // Options = True | False
$no-touch-class-name: no-touchevents; // define class to look for

// Global Box Sizing
//------------------------------------------------
//options = null | content-box | border-box
$box-model: border-box;

// Page Loading Transitions
//---------------------------------------------------
$page-transitions: false;
$page-loaded-class: loaded;

$page-fade-duration: 0.5s;
$page-overlay-color: $white;

$loader-type: bar-left;
$loader-color: $swatch-1;
$loader-size: 1px;

// Global radius
//---------------------------------------------------
$radius: 0.2rem;

// Global speed transitions such as fades and animations
//------------------------------------------------------
$global-transition-speed: 0.25s;

// Global accent-color // default fallback
//---------------------------------------------------
$global-accent-color: $swatch-4;

//--------------------------------------------------
// *********** VERTICAL RHYTHM ************
//--------------------------------------------------

// toggles baseline-grid -- used for dev purposes only
$baseline-grid: off; // options = on | off
$px-offset: 0;

// Learn about axiomatic spacing @ https://zellwk.com/blog/why-vertical-rhythms/
$vertical-rhythm: true; // creates a vertical baseline grid for element spacing and typography
$base-unit: 16px; // recommended do not change
$vr-base: 2; // $vr-base = vertical spacing unit for all html elements

// Learn about axiomatic spacing @ https://alistapart.com/article/axiomatic-css-and-lobotomized-owls
$axiomatic-spacing: off; // options = on | off  - This defaults a consistent spacing for all html elements = $vr-base value
$vertical-rhythm-element-name: main; // this allows you to scope your vertical ryhtym and axiomatic spacing to only apply within a certain tag or class such as 'main'

// Global Spacers
// ------------------------------------------------

// margin spacers prefix name scope
$margin-uniform-prefix: m; //margin on all 4 sides
$margin-horizontal-prefix: mh; //horizontal margin only
$margin-vertical-prefix: mv; //vertical margin only
$margin-top-prefix: mt; //margin top only
$margin-bottom-prefix: mb; //margin bottom only

// padding spacers prefix name scope
$padding-uniform-prefix: p; //padding on all 4 sides
$padding-horizontal-prefix: ph; //horizontal padding only
$padding-vertical-prefix: pv; //vertical padding only
$padding-top-prefix: pt; //padding top only
$padding-bottom-prefix: pb; //padding bottom only

// Global spacing map - appended to prefixes
$global-spacing: (
  "none": 0,
  "xxs": $vr-base * 0.25,
  "xs": $vr-base * 0.5,
  "s": $vr-base * 1,
  "ms": $vr-base * 1.5,
  "m": $vr-base * 2,
  "ml": $vr-base * 2.5,
  "l": $vr-base * 3,
  "xl": $vr-base * 3.5,
  "xxl": $vr-base * 4
);

//--------------------------------------------------
// *********** TYPOGRAPHY ************
//--------------------------------------------------

// TYPE SETTINGS - GLOBAL
//----------------------------------------

$type-styling: true; // overwrites browser defaults and sets global type styles

// Loads Font Asset files - Does not apply css styling
//---------------------------------------------------

// Load Heading Font Files
$heading-font-family: null; // Font Family name
$heading-font-path: null; // set path to assets

// map to load and set font-styles and font-weights
$load-heading-font: ();

// Load Body Font Files
$body-font-family: "SJ Sans"; // Font Family name
$body-font-path: "https://www.stjude.org/etc/clientlibs/stjude/shared/fonts/sj-sans/"; // set path to assets

// map to load and set font-styles and font-weights
$load-body-font: (
  "sjs-light": (
    normal,
    300
  ),
  "sjs-regular": (
    normal,
    400
  ),
  "sjs-medium": (
    normal,
    600
  ),
  "sjs-book": (
    normal,
    700
  ),
  "sjs-bold": (
    normal,
    800
  )
);

// Font stacks
//----------------------------------------

// Sans-serif
$calibri: "Calibri", "Candara", "Segoe", "Segoe UI", "Optima", "Arial",
  "sans-serif";
$candara: "Candara", "Calibri", "Segoe", "Segoe UI", "Optima", "Arial",
  "sans-serif";
$courier: "Courier New", "Courier", "Lucida Sans Typewriter",
  "Lucida Typewriter", "monospace";
$franklin: "Franklin Gothic Medium", "Arial", "sans-serif";
$futura: "Futura", "Trebuchet MS", "Arial", "sans-serif";
$geneva: "Geneva", "Tahoma", "Verdana", "sans-serif";
$gill-sans: "Gill Sans", "Gill Sans MT", "Calibri", "sans-serif";
$helvetica: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
$lucida-grande: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "Geneva",
  "Verdana", "sans-serif";
$optima: "Optima", "Segoe", "Segoe UI", "Candara", "Calibri", "Arial",
  "sans-serif";
$segoe: "Segoe", "Segoe UI", "Helvetica Neue", "Arial", "sans-serif";
$tahoma: "Tahoma", "Geneva", "Verdana", "sans-serif";
$trebuchet: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode",
  "Lucida Sans", "Tahoma", "sans-serif";
$verdana: "Verdana", "Geneva", "sans-serif";
$open-sans: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
$sj-sans: "SJ Sans", "Open Sans", "Helvetica Neue", "Helvetica", "Arial",
  "sans-serif";

// Serif
$antiqua: "Book Antiqua", "Palatino", "Palatino Linotype", "Palatino LT STD",
  "Georgia", "serif";
$baskerville: "Baskerville", "Baskerville old face", "Hoefler Text", "Garamond",
  "Times New Roman", "serif";
$bodoni: "Bodoni MT", "Didot", "Didot LT STD", "Hoefler Text", "Garamond",
  "Times New Roman", "serif";
$cambria: "Cambria", "Georgia", "serif";
$caslon: "Big Caslon", "Book Antiqua", "Palatino Linotype", "Georgia", "serif";
$constantia: "Constantia", "Palatino", "Palatino Linotype", "Palatino LT STD",
  "Georgia", "serif";
$didot: "Didot", "Didot LT STD", "Hoefler Text", "Garamond", "Times New Roman",
  "serif";
$garamond: "Garamond", "Baskerville", "Baskerville Old Face", "Hoefler Text",
  "Times New Roman", "serif";
$goudy: "Goudy Old Style", "Garamond", "Big Caslon", "Times New Roman", "serif";
$hoefler: "Hoefler Text", "Baskerville old face", "Garamond", "Times New Roman",
  "serif";
$lucida-bright: "Lucida Bright", "Georgia", "serif";
$palatino: "Palatino", "Palatino Linotype", "Palatino LT STD", "Book Antiqua",
  "Georgia", "serif";
$merriweather: "Merriweather", "sans-serif", "Geneva", "serif";

// Sets type styling -- Applies css styling
//---------------------------------------------------

$heading-font: $sj-sans; // load or create new font stack
$heading-font-weight: 600; // set default heading weight
$heading-font-color: $black; // set default heading color

$body-font: $sj-sans; // load or create new font stack
$body-font-weight: 400; // set default body weight
$body-font-color: $grey-80; // set default body color

// MODULAR SCALE
//----------------------------------------

// Turns Modular Scale for headers on or off -- can be used for font-size modifiers
$modular-scale: true;

// Ratios
//----------------------------------------
$minor-second: 1.067;
$major-second: 1.125;
$minor-third: 1.2;
$major-third: 1.25;
$perfect-fourth: 1.333;
$aug-fourth: 1.414;
$perfect-fifth: 1.5;
$minor-sixth: 1.6;
$golden: 1.618;
$major-sixth: 1.667;
$minor-seventh: 1.778;
$major-seventh: 1.875;
$octave: 2;
$major-tenth: 2.5;
$major-eleventh: 2.667;
$major-twelfth: 3;
$double-octave: 4;

// If Modular Scale is true set these variables
$ms-ratio: $perfect-fourth; //ratios listed above
$ms-base: 1; //multiplier steps (formula = ratio value x steps)

// Heading map for more finite control when modular scale is enabled
// @value = h tag"
// @params = (modular scale increment, line-height, margin-top, margin-bottom)
$headings: (
  "h1": (
    3,
    $vr-base * 1.5rem,
    $vr-base * 0.5rem,
    $vr-base * 0.5rem
  ),
  "h2": (
    3,
    $vr-base * 1.35rem,
    $vr-base * 0.5rem,
    $vr-base * 0.5rem
  ),
  "h3": (
    2,
    $vr-base * 1rem,
    $vr-base * 1rem,
    $vr-base * 0.5rem
  ),
  "h4": (
    0,
    $vr-base * 1.2rem,
    $vr-base * 0.5rem,
    $vr-base * 0.2rem
  ),
  "h5": (
    0,
    $vr-base * 2rem,
    $vr-base + rem,
    null
  ),
  "h6": (
    -1,
    $vr-base * 2rem,
    $vr-base + rem,
    null
  )
);

// NON-MODULAR SCALE TYPOGRAPHY SETTINGS

// $base-font-size: 1rem;
// $base-line-height: 32px;

// Heading map for vanilla heading values when not using Modular Scale
// $non-ms-headings: (
//   "h1": (
//     32px,
//     $vr-base * 1.5rem,
//     $vr-base * 0.5rem,
//     $vr-base * 0.5rem
//   ),
//   "h2": (
//     34px,
//     $vr-base * 1.34rem,
//     $vr-base * 0.5rem,
//     $vr-base * 0.5rem
//   ),
//   "h3": (
//     21px,
//     $vr-base * 1rem,
//     $vr-base * 0.5rem,
//     $vr-base * 0.5rem
//   ),
//   "h4": (
//     16px,
//     $vr-base * 1.28rem,
//     $vr-base + rem,
//     null
//   ),
//   "h5": (
//     18px,
//     $vr-base * 2rem,
//     $vr-base + rem,
//     null
//   ),
//   "h6": (
//     14px,
//     $vr-base * 2rem,
//     $vr-base + rem,
//     null
//   )
// );

// Type modifiers
//----------------------------------------

// font-size prefix name scope
$font-size-modifier-prefix: fs-; //optional
$font-size-modifier-line-height-base: $vr-base + rem; //can be overwrittern in map but recommended to keep type on grid

$font-size-modifiers: (
  "xxl": (
    6,
    4.5rem
  ),
  "xl": (
    5,
    3.9375rem
  ),
  "l": (
    4,
    3.375rem
  ),
  "ml": (
    3,
    2.8125rem
  ),
  "m": (
    2,
    2.25rem
  ),
  "ms": (
    1,
    2.25rem
  ),
  "s": (
    0,
    1.6875rem
  ),
  "xs": (
    -1,
    1.40625rem
  ),
  "xxs": (
    -2,
    1.125rem
  ),
  "xxxs": (
    -3,
    0.6875rem
  )
);

// font-weight prefix name scope
$font-weight-modifier-prefix: fw-; //optional

$font-weight-modifiers: (
  "xlight": 300,
  "light": 400,
  "semi-bold": 600,
  //default
    "bold": 700,
  "xbold": 800
);

// Line-height Modifiers
//----------------------------------------------------

// Generates classes and placeholders for resetting line-heights
// @value = class/placeholder name"
// @params = incremental values of $vr-base when vertical rhythm == true or vanilla values
$line-height-modifiers: (
  "lh-xxl": 2rem,
  "lh-xl": 1.75rem,
  "lh-l": 1.5rem,
  "lh-ml": 1.25rem,
  "lh-m": 1rem,
  "lh-ms": 0.75rem,
  "lh-s": 0.5rem,
  "lh-xs": 0.25rem
);

// Responsive Scaling of Typography & Component Sizing
//---------------------------------------------------
$responsive-scaling: false; // Use to scale type and vertical rhythm at different screen sizes

$responsive-scaling-breakpoints: (
  "1px": 1rem,
  "600px": 1.032rem,
  "900px": 1.25rem
);

//Global Text Links
//---------------------------------------------------
$link-styling: true;
$link-color: $swatch-4; //blue
$link-color-hover: $swatch-1;
$underline-links: false; // true = underlines || false = plain text
$link-hover-style: transition-color;

// Utility Text Colors
//---------------------------------------------------
// @value = class name"
// @params = (text-color, text-color-hover)

$utility-text-color-prefix: text-; //optional value used to target all children to inherit utility text color
$utility-text-colors: (
  "light": (
    $white,
    $swatch-10
  ),
  "dark": (
    $black,
    $swatch-3
  ),
  "wild": (
    aqua,
    lighten(aqua, 5%)
  )
);

// *********** END TYPOGRAPHY ************
//--------------------------------------------------

//--------------------------------------------------
// *********** LAYOUT ************
//--------------------------------------------------

// Content
//----------------------------------------------------
$content-max-width: 1080px; //set max-width value on site content
$content-width: 90%; //set default content width value for all content
$full-width-class-name: full-width; // class name for elements to be full-width

// Generate grid system and classes for components
// ---------------------------------------------------
$generate-grid-classes: true; // true generates classes || set to false to turn off
$grid-breakpoint: 900px; // all columns start at 100% width on mobile - this tells the grid when to kick in

$grid-columns: 12; // number of columns in grid system
$grid-gutter: 2rem; // gutter width || recommended to have same value as $vr-base

// class name set on parent container
$parent-element-class: inner-row; //class name given to parent row/container
$gutter-class-name: gutters; //class name given to parent row/container to make gutters

// Classes = prefixes + number of columns
$grid-class-name-prefix: col-; //set prefix for all grid classes
$offset-class-name-prefix: offset-; //set prefix for offset classes

// Breakpoints Map
//---------------------------------------------------
$breakpoints: (
  "small": 600px,
  "medium": 900px,
  "large": 1200px,
  "site-max": 1800px
);

//--------------------------------------------------
// *********** UI/UX ELEMENTS ************
//--------------------------------------------------

// Global Box Shadows values for z-index depth
//---------------------------------------------------
$box-shadow-map: (
  "depth-level-1": (
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2)
  ),
  "depth-level-2": 0px 3px 20px 0 rgba(0, 0, 0, 0.075),
  "depth-level-3": 0px 3px 50px 0 rgba(0, 0, 0, 0.075),
  "depth-level-4": (
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.3)
  )
);

// SVG Base Size
//---------------------------------------------------
$svg-icon-base-size: 3rem;

//---------------------------------------------------
// BUTTONS
//---------------------------------------------------
$btn-border-base-size: 1px;
$btn-border-base-style: solid;
$btn-border-ext-style: thick-bottom;

$btn-solid-hover-style: simple-fade;
$btn-hollow-hover-style: simple-fade;

// Solid Buttons Map
//-----------------------------------------------------------------------------
// @value = "class name"
// @params = (background-color, border-color, font color, hover-bg-color)

$solid-buttons: (
  "btn-primary": (
    $swatch-4,
    $swatch-4,
    #fff,
    lighten($swatch-4, 10%)
  ),
  "btn-secondary": (
    $swatch-10,
    $swatch-10,
    #fff,
    lighten($swatch-10, 15%)
  ),
  "btn-tertiary": (
    $swatch-10,
    $swatch-10,
    #fff,
    lighten($swatch-10, 15%)
  ),
  "btn-white": (
    $white,
    $white,
    $black,
    $grey-20
  ),
  "cta-1": (
    $swatch-10,
    $swatch-10,
    #fff,
    lighten($swatch-10, 10%)
  ),
  "cta-2": (
    $swatch-1,
    $swatch-1,
    #fff,
    lighten($swatch-1, 10%)
  ),
  "cta-primary": (
    $swatch-10,
    $swatch-10,
    #fff,
    lighten($swatch-10, 10%)
  ),
  "btn-twitch": (
    $twitch,
    $twitch,
    #fff,
    lighten($twitch, 10%)
  )
);

// Hollow Buttons Map
//-----------------------------------------------------------------------------
// @value = "class name"
// @params = (border-color, font color, hover bg color, hover font color)

$hollow-buttons: (
  "btn-error-hollow": (
    #d82d2d,
    #d82d2d,
    #d82d2d,
    $white
  ),
  "btn-success-hollow": (
    #52bf4a,
    #52bf4a,
    #52bf4a,
    $white
  ),
  "btn-warning-hollow": (
    #ff9800,
    #ff9800,
    #ff9800,
    $white
  ),
  "btn-white-hollow": (
    $white,
    $white,
    $white,
    $black
  ),
  "btn-primary-hollow": (
    $swatch-1,
    $swatch-1,
    $swatch-1,
    $white
  ),
  "btn-secondary-hollow": (
    $swatch-10,
    $swatch-10,
    $swatch-10,
    $white
  ),
  "btn-tertiary-hollow": (
    $black,
    $black,
    $black,
    $white
  ),
  "btn-twitch-hollow": (
    $twitch,
    $twitch,
    $twitch,
    $white
  )
);

//---------------------------------------------------
// BACKGROUND COLORS / OVERLAYS
//---------------------------------------------------
$background-swatches: (
  "gradient-header": linear-gradient(120deg, $swatch-2 0, $swatch-1),
  "bg-white": $white,
  "bg-black": $black,
  "bg-light-grey": $light-grey,
  "bg-medium-grey": $medium-grey,
  "bg-dark-grey": $dark-grey,
  "bg-swatch-1": $swatch-1,
  "bg-swatch-2": $swatch-2,
  "bg-swatch-3": $swatch-3,
  "bg-swatch-4": $swatch-4,
  "bg-swatch-5": $swatch-5,
  "bg-swatch-6": $swatch-6,
  "bg-swatch-7": $swatch-7,
  "bg-swatch-8": $swatch-8,
  "bg-swatch-9": $swatch-9,
  "bg-swatch-10": $swatch-10,
  "bg-swatch-11": $swatch-11,
  "bg-swatch-12": $swatch-12
);

//---------------------------------------------------
// ACCENT BORDERS
//---------------------------------------------------
$global-accent-border-size: 5px;

// loops stores colors in variables to be added via accent border mixin
$accent-borders: (
  "accent-border-primary": $swatch-1,
  "accent-border-2": darken($swatch-10, 10%),
  "accent-border-white": $white,
  "light-grey": $grey-10,
  "blue-gradient": linear-gradient(120deg, $swatch-4 0, $swatch-9)
);

//---------------------------------------------------
// FORMS
//---------------------------------------------------

//Coming Soon
