[class^=btn-] {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    vertical-align: top;
}

.btn-quadinary {
    border-radius: 50%;
    text-align: center;
    font-size: 0px;
    transition: all .25s ease-in-out;
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%22750%22%20height%3D%22750%22%3E%3Cpath%20fill%3D%22%231874DC%22%20stroke%3D%22%22%20stroke-width%3D%22%22%20d%3D%22M421.19%20495.8L531%20375%20421.19%20254.22c-11.02-11.943-29.61-12.767-41.644-1.847-12.035%2010.92-13.015%2029.501-2.196%2041.627l46.1%2051H249c-16.569%200-30%2013.431-30%2030%200%2016.569%2013.431%2030%2030%2030h174.78l-46.37%2051c-10.625%2012.142-9.573%2030.555%202.364%2041.408%2011.938%2010.853%2030.368%2010.152%2041.446-1.578l-.03-.03z%22%2F%3E%3C%2Fsvg%3E');
    background-color: #fff;
    width: 2.8125rem;
    height: 2.8125rem;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100%;
}