body {
  counter-reset: rank;
}

html {
  scroll-behavior: smooth;
}

.relay {
  .grant-access {
    background-color: $medium-grey;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px;
    z-index: 9998;
  }

  h1 {
    color: $grey-90;
  }

  // img {
  //   border-radius: $radius;
  // }

  .disclaimer {
    @include _(mt-xs);
    @include _(fs-s);
    font-style: italic;
    display: block;
  }

  .progress_wrapper {
    background: $grey-90;
    // height: 280px;

    @include breakpoint(medium) {
      // height: 200px;
    }

    &.embed {
      @include _(mt-s);
      background: transparent;
      width: 310px;
      height: auto;
      background: #bbbcbc;
      border-radius: $radius;

      .embed_title {
        @include _(fs-ms);
        @include _(fw-xbold);
        color: #333f48;
        // text-shadow: 1px 1px 3px #333;
      }

      .progress {
        @include _(ph-xs);
        @include _(pt-xs);
        @include _(pb-s);
      }

      .cta-embed-primary {
        margin-top: 1.5rem;
        width: 100%;
      }

      .progress_wrapper__therm {
        background-color: $grey-10;
      }

      .progress_wrapper__raised {
        @include _(fw-xbold);
        font-size: 0.8rem;
        line-height: 1.5rem;
        // text-shadow: 1px 1px 3px #333;
        color: #333f48;
        float: left;
      }

      .progress_wrapper__goal {
        @include _(fw-xbold);
        font-size: 0.8rem;
        line-height: 1.5rem;
        // text-shadow: 1px 1px 3px #333;
        color: #333f48;
      }
    }

    .progress {
      opacity: 0;
      animation: progress ease-in-out 0.5s 1.3s 1 forwards;
      position: relative;

      .milestone_title_wrapper {
        @include _(mt-s);
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .hide {
          display: none;
        }
      }

      .milestone_header {
        @include _(fs-ms);
        @include _(fw-xbold);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .milestone_title {
        @include _(fs-s);
        @include _(fw-xbold);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @keyframes progress {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    section {
      width: 100%;
      @include _(m-none);
      @include _(ph-s);
      @include _(pv-s);

      @include breakpoint(medium) {
        @include _(pv-xxs);
        @include _(ph-none);
      }

      & > [class*="col-"] {
        @include _(mt-none);
        @include breakpoint(medium) {
          @include _(mt-xxs);
        }
      }
    }

    span {
      @include _(fw-xbold);
      // @include _(fs-ms);
      color: $white;
    }

    &__raised {
      font-size: 1rem;
      line-height: 2rem;
      width: fit-content;

      @include breakpoint(medium) {
        font-size: 1.776889rem;
        line-height: 2.25rem;
      }
    }

    &__goal {
      font-size: 1rem;
      line-height: 2rem;
      width: fit-content;
      float: right;
      @include breakpoint(medium) {
        font-size: 1.776889rem;
        line-height: 2.25rem;
        display: inline;
        float: right;
      }
    }

    &__text {
      @include _(mb-xxs);
      position: relative;

      font-style: italic;

      p {
        color: #fff !important;
      }
    }

    &__embed_text {
      @include _(mb-xxs);
      position: relative;
      color: $white;
      font-size: 1.5rem;
    }

    &__therm {
      position: relative;
      width: 100%;
      height: 15px;
      background-color: white;
      border-radius: $radius;

      &__inner {
        height: 15px;
        background-color: $primary;
        border-radius: $radius;
      }

      .milestones {
        height: 15px;
        width: 100%;
        position: absolute;
        top: -2px;

        ul {
          @include _(p-none);
          @include _(m-none);
          list-style-type: none;
          width: 100%;

          li {
            cursor: pointer;

            span {
              @include _(fs-xs);
              line-height: 1rem;
              visibility: hidden;
              margin-top: 10px;
              display: block;
              @include breakpoint(medium) {
                @include _(mt-xs);
                font-size: 1rem;
                line-height: 1.6875rem;
              }
            }

            &.higher {
              .show {
                visibility: visible;
              }
              // .hide {
              //   visibility: hidden;
              // }
            }

            &.lower {
              .show {
                visibility: visible;
              }
            }
          }

          .lower {
            &:before {
              // display: none;
              opacity: 0.5;

              .show {
                visibility: visible;
              }

              // span {
              //   visibility: hidden;
              // }
            }
          }

          // .higher {
          //   span {
          //     visibility: hidden;
          //   }
          // }

          // > .higher {
          //   span {
          //     // &:before {
          //     //   content: "";
          //     //   display: block;
          //     //   position: absolute;
          //     //   left: 10px;
          //     //   top: -5px;
          //     //   border-bottom: 5px solid $grey-60;
          //     //   border-left: 5px solid transparent;
          //     //   border-right: 5px solid transparent;
          //     // }
          //     position: absolute;
          //     width: 200px;
          //     cursor: default;

          //     @include breakpoint(medium) {
          //       @include _(ph-xxs);
          //       width: auto;
          //       visibility: visible;
          //       position: absolute;
          //       top: 20px;
          //       left: 0px;
          //     }

          //     // background-color: $grey-60;
          //     // border-radius: $radius;
          //   }

          //   // ~ .higher {
          //   //   span {
          //   //     visibility: hidden;
          //   //   }
          //   // }
          // }

          li {
            @include _(p-none);
            @include _(m-none);
            display: inline;
            position: absolute;
            height: 15px;
            // width: 100%;
            // border-left: 2px solid red;

            @include breakpoint(medium) {
              width: 100%;
            }

            &:hover:before {
              background-color: $primary;
            }

            &:before {
              content: "";
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 3px solid rgb(255, 255, 255);
              display: block;
              background-color: rgb(25, 111, 204);
            }
          }
        }
      }
    }
  }

  .steps_wrapper {
    // @include _(mb-m);
    @include _(pt-s);
    @include _(pb-xxs);
    background: $grey-10;
    position: relative;

    .overlay_lp__content {
      @include _(mb-m);
    }

    .embed-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .opening {
      p {
        @include _(fs-ms);
        @include _(mv-s);
        &:first-child {
          @include _(mt-none);
        }
      }

      ul {
        @include _(mt-none);
      }

      span {
        // font-weight: bold;
        cursor: pointer;
        color: $accent;
        &:hover {
          color: $primary;
        }
      }
      &:after {
        content: "";
        width: 10%;
        height: 5px;
        display: block;
        background-color: $dark-grey;
        margin: 50px auto 20px;
      }
      @include breakpoint(medium) {
        &:after {
          display: none;
        }
      }
    }

    // &:after {
    //   content: "";
    //   display: block;
    //   width: 10%;
    //   height: 2px;
    //   background-color: $medium-grey;
    //   margin: 0 auto;
    // }

    // &:after {
    //   content: "";
    //   position: absolute;
    //   bottom: -35px;
    //   transform: rotate(-2deg);
    //   height: 70px;
    //   width: 100%;
    //   background-color: $grey-10;
    // }
  }

  .ways {
    @include _(mt-s);

    p {
      @include _(mb-s);
      text-align: center;
    }

    .col-2 {
      margin-top: 0;
    }

    // &:before {
    //   content: "";
    //   display: block;
    //   width: 10%;
    //   height: 2px;
    //   background-color: #cccccc;
    //   margin: 0 auto;
    // }

    p {
      @include _(mt-s);
    }

    ol {
      @include _(pt-xxs);
      @include _(pb-s);
      @include _(mv-none);
      list-style: none;
      border-bottom: 1px solid #ccc;

      .nav-item {
        span {
          color: #135cb0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: nowrap;
          margin-right: 0.5rem;
          cursor: pointer;

          span {
            @include _(fs-m);
            @include _(mv-none);
            @include _(pt-none);
            text-align: left;
          }

          & > .cta-text {
            @include _(mt-none);
            @include _(p-none);
            @include _(fs-xs);
            margin-bottom: -2rem;
            position: relative;
            min-height: 0.85rem;
            color: #4d4d4d;
            background: transparent;
            box-shadow: none;
            width: 70px;
            overflow: visible;
            text-align: center;
            display: block;
            visibility: hidden;

            @include breakpoint(medium) {
              visibility: visible;
            }

            &:before {
              content: "";
              width: 2.75rem;
              height: 2.75rem;
              background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%22750%22%20height%3D%22750%22%3E%3Cpath%20fill%3D%22%23135CB0%22%20stroke%3D%22%22%20stroke-width%3D%22%22%20d%3D%22M498.67%20335H415v-83.67c0-22.091-17.909-40-40-40s-40%2017.909-40%2040V335h-83.66c-22.091%200-40%2017.909-40%2040s17.909%2040%2040%2040H335v83.66c0%2022.091%2017.909%2040%2040%2040s40-17.909%2040-40V415h83.67c22.091%200%2040-17.909%2040-40s-17.909-40-40-40%22%2F%3E%3C%2Fsvg%3E);
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: 100%;
              display: block;
              margin: 0 auto;
              position: absolute;
              top: -1.5rem;
              right: -0.2rem;
              visibility: visible;

              @include breakpoint(medium) {
                right: calc(50% - 1.375rem);
                top: -2.2rem;
              }
            }

            &:after {
              display: none;
            }
          }
        }

        &.expanded {
          span {
            & > .cta-text {
              &:before {
                content: "";
                background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%22750%22%20height%3D%22750%22%3E%3Cpath%20fill%3D%22%23135CB0%22%20stroke%3D%22%22%20stroke-width%3D%22%22%20d%3D%22M498.67%20415H251.33c-22.091%200-40-17.909-40-40s17.909-40%2040-40h247.34c22.091%200%2040%2017.909%2040%2040s-17.909%2040-40%2040%22%2F%3E%3C%2Fsvg%3E);
              }
            }
          }
        }
      }

      .item {
        max-height: 0;
        transition: max-height 0.5s ease-out;
        overflow: hidden;

        ul {
          @include _(p-none);
          list-style: none;
        }

        p {
          text-align: left;
        }

        &.expanded {
          max-height: 1000px;
          transition: max-height 0.5s ease-in;
        }
      }
    }
  }

  .donation_wrapper {
    @include _(mb-s);
    @include _(pb-xxs);
    // height: 300px;
    background-color: #e6e6e6;
    position: relative;

    // &:before {
    //   content: "";
    //   position: absolute;
    //   top: -35px;
    //   transform: rotate(-2deg);
    //   height: 70px;
    //   width: 100%;
    //   background-color: #e6e6e6;
    // }

    &:after {
      content: "";
      position: absolute;
      bottom: -35px;
      transform: rotate(-2deg);
      height: 70px;
      width: 100%;
      background-color: #e6e6e6;
    }
  }

  .prizes_wrapper {
    background: url("../../images/lb_bg2.png") no-repeat;
    background-color: #94dee7;
    background-position: center 400%;
    background-size: contain;
    position: relative;
    min-height: 800px;
    transition: 0.8s ease-in-out 0.1s;

    &.is-visible {
      background-position: center bottom;
    }

    .rewards_wrapper {
      @include _(p-none);
      background-color: $swatch-12;
      // box-shadow: 2px 2px 15px #777;
      // border-radius: $radius;
      box-shadow: 8px 8px $grey-50;

      .prizes__amount {
        @include _(ph-xxs);
        @include _(pv-none);
        @include _(fs-ms);
        @include _(fw-bold);
        position: absolute;
        top: 0.5rem;
        left: 10px;
        background-color: $swatch-1;
        color: $white;
        border-radius: $radius;
      }

      span {
        @include _(fs-ms);
        @include _(ph-xs);
        @include _(pv-xs);
        @include _(mh-none);
        display: flex;
        justify-content: center;
        align-items: center;
        display: block;
        // font-family: Barlow_Condensed_SemiBold;

        &.title {
          @include _(ph-xxs);
          @include _(pb-none);
          @include _(pt-xxs);
          @include _(fs-m);
          @include _(fw-xbold);
          color: $grey-90;
          // height: 96px;
        }
      }
    }

    &:after {
      content: "";
      height: 55px;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      background-image: linear-gradient(360deg, #fff, transparent);
    }
  }

  .incentives_wrapper {
    background: url("../../images/lb_bg3.png") no-repeat;
    background-color: #94dee7;
    background-size: contain;
    background-position: center bottom;

    .rewards_wrapper {
      @include _(p-none);
      background-color: $swatch-12;
      // box-shadow: 2px 2px 15px #777;
      // border-radius: $radius;
      box-shadow: 8px 8px $grey-50;

      .prizes__amount {
        @include _(ph-xxs);
        @include _(pv-none);
        @include _(fs-ms);
        @include _(fw-bold);
        position: absolute;
        top: 0.5rem;
        left: 10px;
        background-color: $swatch-1;
        color: $white;
        border-radius: $radius;
      }

      span {
        @include _(fs-ms);
        @include _(ph-xs);
        @include _(pv-xs);
        @include _(mh-none);
        display: flex;
        justify-content: center;
        align-items: center;
        display: block;
        // font-family: Barlow_Condensed_SemiBold;

        &.title {
          @include _(ph-xxs);
          @include _(pb-none);
          @include _(pt-xxs);
          @include _(fs-m);
          @include _(fw-xbold);
          color: $grey-90;
          // height: 96px;
        }
      }
    }
  }

  .patient_wrapper {
    @include _(mb-none);
    // @include _(mt-l);
    background-image: url("../../images/hospital-bg.jpg");
    background-size: 1000%;
    // background-position: 50%;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    @include breakpoint(medium) {
      background-size: auto;
      background-position: 50% 90%;
    }

    p {
      color: $white;
    }
  }

  .overlay_lp {
    &__top {
      // background: url(/images/gt-hero1.jpg) no-repeat -13% 25%;
      // background-size: 100%;

      // background: url("../../images/relay_hero3.jpg") no-repeat 45% 70%;

      // background: url("../../images/2024_bg.png");
      // background-position: center center;
      // background-size: contain;
      // background-repeat: no-repeat;
      background-color: #94dee7;

      @include breakpoint(medium) {
        // background: url("../../images/relay_hero3.jpg") no-repeat 45% 70%;
        // background-size: 100%;
        // background: linear-gradient(
        //       rgba(51, 51, 51, 0.5) 0%,
        //       rgba(51, 51, 51, 0.9) 100%
        //     )
        //     center center / cover,
        //   url("../../images/relay-bg.jpg");
        background: url("../../images/2024_bg.png");
        background-color: #94dee7;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 42%;
      }

      &.form {
        .hero {
          @include breakpoint(medium) {
            height: 100px !important;
          }
        }
      }

      // .hero {
      //   @include _(p-s);
      //   @include breakpoint(medium) {
      //     width: 80%;
      //     margin: 0 auto;
      //     padding-left: 0;
      //   }
      //   @include breakpoint(small) {
      //     height: 50vh;
      //     min-height: 15rem;
      //   }

      //   .cta-primary {
      //     @include _(fs-ms);
      //     width: 100%;
      //     @include breakpoint(medium) {
      //       width: 20%;
      //     }
      //   }

      //   .cta-secondary {
      //     @include _(fs-ms);
      //   }

      //   button {
      //     @include _(mt-xs);
      //     width: 100%;

      //     @include breakpoint(medium) {
      //       width: 50%;
      //     }
      //   }

      //   &__content {
      //     &__box {
      //       @include _(p-xs);
      //       // background: rgba(225, 225, 225, 0.8);
      //       // width: fit-content;
      //       text-align: left;

      //       @include breakpoint(medium) {
      //         // margin: 0 auto;
      //         // text-align: center;
      //         // width: 750px;
      //         width: 50%;
      //       }

      //       h1 {
      //         @include _(fw-xbold);
      //         @include _(fs-l);
      //         color: $white;
      //       }

      //       p {
      //         @include _(fs-sm);
      //         color: $white;

      //         a {
      //           color: $white;
      //           text-decoration: underline;
      //         }
      //       }
      //     }
      //     &__logo {
      //       width: 50%;
      //       img {
      //         max-width: 60%;
      //       }
      //     }
      //   }
      // }
    }

    &__content {
      @include _(mb-l);

      &.leaderboard {
        @include _(mt-s);
        @include _(mb-m);
        width: 100%;

        .col-4 {
          @include _(mt-none);
        }

        h2 {
          @include _(mb-s);
        }
        .leaderboard_wrapper {
          // @include _(fw-bold);
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $grey-10;

          &__name {
            a {
              color: #000;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
            &:before {
              @include _(fw-bold);
              counter-increment: rank;
              content: counter(rank);
              margin-right: 1rem;
            }

            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &__amount {
            // @include _(fw-bold);
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &.bestpractices {
        @include _(mv-m);
        ul,
        p {
          width: 100%;
        }

        iframe {
          display: block;
          border: none;
          height: 200vh;
          width: 100%;
        }
      }

      &.digital {
        text-align: center;

        p {
          @include _(mv-s);
        }

        h1 {
          color: $grey-90;
        }

        .cta-primary {
          width: 100%;
        }

        @include breakpoint(medium) {
          img {
            width: 70%;
          }

          .cta-primary {
            width: 40%;
          }
        }
      }

      &.c_challenge {
        @include _(mt-m);
        @include _(mb-none);
        text-align: center;
        // background: url("../../images/lb_bg.png");
        background-position: right top;
        background-repeat: no-repeat;

        .cta-primary {
          @include _(fs-ms);
          @include _(mb-s);
          width: 100% !important;

          @include breakpoint(medium) {
            width: 40% !important;
          }
        }

        .instructions {
          width: 100%;
          @include _(pt-xxs);
          @include _(pb-ms);
          // @include _(mb-m);
          text-align: left;
          position: relative;
          h2 {
            @include _(mt-none);
            @include _(pt-none);
            text-align: left;
          }
          span {
            cursor: pointer;
            color: $accent;
            &:hover {
              color: $primary;
            }
          }
          div {
            @include _(pv-ms);
          }

          .new-ways {
            @include _(mt-ms);
            @include _(p-s);
            border-radius: $radius;
            background: $grey-10;

            h2 {
              @include _(mt-none);
              @include _(mb-s);
            }

            ul {
              @include _(mt-xxs);
              list-style-type: none;
            }
          }
          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            display: block;
            width: 10%;
            height: 2px;
            background-color: $medium-grey;
            margin: 0 auto;
          }
          // &:after {
          //   content: "";
          //   position: absolute;
          //   bottom: 0;
          //   left: 0;
          //   right: 0;
          //   display: block;
          //   width: 10%;
          //   height: 2px;
          //   background-color: $medium-grey;
          //   margin: 0 auto;
          // }
        }
      }

      &.donation-rewards {
        background-color: $light-grey;
      }

      h3 {
        @include _(fs-ms);
        color: $dark-grey;
        text-align: center;
      }

      &.opt-in {
        background-color: $dark-grey;
        width: 100%;
      }

      .steps {
        @include _(mt-ms);
        @include _(pb-ms);
        text-align: center;
        position: relative;
        overflow: hidden;

        img {
          width: 20%;
        }

        p {
          @include _(mt-xxs);
          // text-align: left;

          &.steps {
            &__header {
              @include _(fw-bold);
              @include _(fs-ms);
            }
            &__content {
              @include _(fs-s);
            }
          }
        }

        &-opt {
          @include _(mt-none);
          @include _(pv-xs);
          text-align: center;
          background-color: $dark-grey;

          p {
            @include _(p-xxs);
            width: 80%;
            margin: 0 auto;
            color: $white;
            &.small-text {
              @include _(fs-xs);
            }
          }
        }
      }

      .steps-cta {
        @include _(mb-ms);
        text-align: center;
      }

      .challenge-wrap-1 {
        @media (min-width: 900px) {
          width: calc(41.6666666667%);
          margin: 1rem 0 0 0;
        }
      }

      .challenge-wrap-2 {
        @media (min-width: 900px) {
          width: calc(58.3333333333%);
          margin: 1rem 0 0 0;
        }
      }

      .challenges {
        @include _(mv-xxs);
        @include _(pb-s);
        border-radius: $radius;
        height: 200px;
        text-align: center;
        position: relative;
        overflow: hidden;
        background: $grey-10;
        cursor: pointer;
        transition: background 0.3s ease-in-out 0.1s;

        @media (min-width: 900px) {
          width: calc(50% - 1rem);
          margin: 0.5rem;
        }

        &:before {
          content: "";
          display: block;
          height: 10px;
        }

        h3 {
          color: $white;
        }

        h2 {
          @include _(fw-semi-bold);
        }

        .default-content {
          @include _(pt-xxs);
          width: 100%;
          height: 100%;
          display: table;
          position: absolute;
          bottom: 0;
          transition: opacity 0.25s ease-in-out, bottom 0.3s ease-in-out 0.1s;

          h3 {
            @include _(mv-xs);
          }

          .content {
            display: table-cell;
            vertical-align: middle;
          }
        }

        .hover-content {
          @include _(p-xs);
          // @include _(pt-xxs);
          width: 100%;
          height: 100%;
          vertical-align: middle;
          display: table;
          position: absolute;
          top: 100%;
          transition: top 0.3s ease-in-out 0.1s;

          .content {
            display: table-cell;
            vertical-align: middle;

            p {
              @include _(fs-s);
              @include _(pb-xs);
              color: $white;
            }
          }
        }

        &:hover {
          @media (min-width: 900px) {
            background: $grey-70;

            .default-content {
              opacity: 0;
              bottom: 100%;
            }

            .hover-content {
              top: 0;
            }
          }
        }

        &-four {
          @include _(mh-none);
          padding: 0 2rem 2rem;
          width: 100%;
          // background-color: #ddd;
          text-align: center;

          h3 {
            @include _(mt-none);
            @include _(mb-s);
          }

          ul {
            text-align: left;
          }

          button {
            @include _(mt-s);
            background-color: transparent;
            border: 0;
            span {
              background-color: $link-color;
              border-color: $link-color;
              border-bottom-color: #0e4482;
              // color: $dark-grey;

              a {
                color: $white;
              }
              &:hover {
                background-color: $link-hover-color;
              }
            }
          }

          @media (min-width: 900px) {
            margin: 0.5rem;
          }
        }
      }

      .options {
        height: 100px;
        background-color: #ddd;
        text-align: center;
      }

      &.prizes {
        @include _(mb-none);
        @include _(pb-m);
        // background: url("../../images/lb_bg2.png") no-repeat;
        // background-position: 50% 50%;
        // background-size: contain;

        div {
          text-align: center;
          position: relative;
        }

        .cta-primary {
          @include _(fs-ms);
          @include _(mv-ms);
          width: 100% !important;
          @include breakpoint(medium) {
            width: 40% !important;
          }
        }

        // img {
        //   border-radius: $radius;
        // }

        .sticker_lang {
          @include _(mt-xs);
          position: relative;
          border-radius: $radius;
          background-color: $light-grey;

          &__left {
            width: 50%;
            text-align: center;
            @include breakpoint(medium) {
              width: 120px;
              position: absolute;
              left: 10px;
              top: -10px;
            }
          }
          &__right {
            display: none;
            @include breakpoint(medium) {
              display: block;
              width: 120px;
              position: absolute;
              right: 0;
              top: -10px;
            }
          }

          p {
            @include _(p-xxs);
            @include _(fw-bold);
            width: 75%;
            margin: 0 auto;

            span {
              @include _(fs-xs);
              display: block;
              font-style: italic;
            }
          }
        }

        // .prizes__amount {
        //   @include _(ph-xs);
        //   @include _(pv-xxs);
        //   @include _(fs-ms);
        //   @include _(fw-bold);
        //   position: absolute;
        //   top: 1rem;
        //   background-color: $swatch-9;
        //   color: $white;
        //   border-radius: $radius;
        // }

        img {
          max-width: 100%;
          text-align: center;
        }
        // .item {
        //   max-width: 70%;
        // }
      }

      // &.rules {
      //     ul {
      //         @include _(mt-xxs);
      //     }
      //     p, li {
      //         @include _(fs-xs);
      //         @include _(mt-xxs);                }
      // }

      &.patient {
        @include _(pb-s);
        @include _(mb-none);
        .photo {
          img {
            border-radius: $radius;
          }
          p {
            @include _(fs-xs);
            color: $white;
            font-style: italic;
          }
        }

        .blockquote {
          @include _(mt-none);
          text-align: center;
          color: $white;

          p {
            @include _(mt-s);
            @include _(fw-bold);
            @include _(fs-ms);
            line-height: 2.25em;
          }

          a {
            color: $white;
            &:hover {
              color: $primary;
            }
          }

          &:before,
          &:after {
            @include _(fs-xxl);
            display: block;
            font-family: serif;
            text-align: center;
          }

          &:before {
            content: open-quote;
            position: relative;
            top: 1.125rem;
          }

          &:after {
            content: close-quote;
            margin-top: 2.8125rem;
          }
        }
      }
    }
  }
}
