.instructions {
    width: 80%;
    margin: 0 auto 4rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    // border-top: 1px solid $medium-grey;
    // padding-top: 4rem;

    h2 {
        color: $grey-70;
        @include _(mb-s);
    }

    p {
        color: $grey-70;
    }

    ol {
        @include _(mt-xs);
        @include _(mh-none);
        @include _(ph-xs);

        li {
            @include _(ph-xxs);
        }
    }

    .i-steps {
        @include _(p-s);
        background-color: $light-grey;
        border: 1px solid $medium-grey;
        border-radius: $radius;

        &:first-child {
            margin-left: 0;
        }
        
        p {
            @include _(pb-xs);
            border-bottom: 1px solid $medium-grey;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        thead {
            background-color: $light-grey;
            tr {
                th {
                    @include _(pv-xxs);
                    @include _(ph-xs);
                    border: 1px solid $medium-grey;
                    text-align: left;
                }
            }
        }
        tbody {
            tr {
                td {
                    @include _(pv-xxs);
                    @include _(ph-xs);
                    border: 1px solid $medium-grey;
                }
            }
        }
    }

    .poll-edit {
        width: 250px;
    }

    .poll-id {
        width: 500px;
    }

    &.ar {
        @include _(pt-s);
        @include _(mt-s);
        // border-top: 1px solid #ddd;
        text-align: center;

        .i-steps {
            background: transparent;
            border: 0;
        }

        .discord {
            text-align: center;

            ol {
                li {
                    text-align: left;
                }
            }

            // p {
            //     text-align: left;
            // }

            .no-border {
                border: 0;
            }

            img {
                @include _(pt-xs);
                width: 50%;
            }

        }
    }
}

@include breakpoint(small) {
    
}

@include breakpoint(medium) {
    
}

@include breakpoint(large) {
    // max-width: 1080px;
    // margin: 0 auto;
}